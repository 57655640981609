import { CaretUpOutlined } from "@ant-design/icons";
import { Breadcrumb, Card, Col, Layout, Row, Tag, theme } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardCards from "../components/dashboardCards";
import LiquidHeart from "../components/liquidHeart";
import PieChart from "../components/pieChart";
import { Tabs } from "antd";
import PaidChart from "../components/paidChart";
import RevenueChart from "../components/revenueChart";
import DoubleBar from "../components/doubleBar";
import { GetDeductionAction, GetFinalReportAction } from "../utils/action";
const Dashboard = () => {
  const [deduce, setDeduce] = useState();
  const [report, setReport] = useState();
  const [pieData, setPieData] = useState([]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigate = useNavigate();
  const [checkLogin, setCheckLogin] = useState(false);
  const getLogin = () => {
    let data = localStorage.getItem("jaypee");
    if (!data) {
      setCheckLogin(false);
      navigate("/");
    }
  };
  useEffect(() => {
    getLogin();
    getFinalReportData();
  }, []);
  const onChange = (key) => {
    console.log(key);
  };
  const getFinalReportData = async () => {
    let data2 = await GetDeductionAction();
    let data = await GetFinalReportAction();

    if (data && data.success && data2) {
      setDeduce(data2.deduction[0].deduction);
      setReport(data.finalReport);

      // console.log(data.finalReport, "this is array");
      return null;
    }
  };
  useEffect(() => {
    let arr = [];
    if (report && report.length > 0) {
      report.map((item, kk) => {
        let obj = {
          type: item.departmentName,
          value: item.grossContributionPercent,
        };
        arr.push(obj);
      });
      console.log(arr, " th is si array  of gross");
      setPieData(arr);
    }
  }, [report]);
  return (
    <Content
      style={{
        margin: "0 16px",
      }}
    >
      {/* <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
      </Breadcrumb> */}
      <DashboardCards />
      <Tabs
        type="card"
        tabBarStyle={{ position: "relative", top: 16, right: 1 }}
      >
        <Tabs.TabPane tab="Occupancy" key="occupancy">
          <div
            style={{
              padding: 0,
              minHeight: 300,
              background: colorBgContainer,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            {/* <div className="flex-1"> */}
            <PieChart
              data={pieData}
              // style={{ width: 400, marginLeft: "auto", marginRight: "auto" }}
            />
            {/* </div> */}

            {/* <div className=" flex-1"> */}
            <LiquidHeart
            // style={{ width: 200, marginLeft: "auto", marginRight: "auto" }}
            />
            {/* </div> */}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Revenue" key="revenue">
          <Card>
            <div className="w-full h-72 pl-3">
              <div className="text-center ml-auto mr-auto">
                <Tag>All Time</Tag>
              </div>

              <RevenueChart />
            </div>
          </Card>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Transactions" key="transactions">
          <Card>
            <div className="w-full h-72 pl-3">
              <div className="text-center ml-auto mr-auto">
                <Tag>All Time</Tag>
              </div>

              <DoubleBar />
            </div>
          </Card>
        </Tabs.TabPane>
      </Tabs>
    </Content>
  );
};

export default Dashboard;
