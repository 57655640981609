import {
  Breadcrumb,
  Button,
  Card,
  Tag,
  theme,
  Tooltip,
  Drawer,
  Timeline,
  Table,
  Modal,
  Upload,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/sidebar";
import { TableComp } from "sz-react-utils4";
import moment from "moment";
import {
  BookOutlined,
  DollarOutlined,
  FolderViewOutlined,
  IdcardOutlined,
  MessageOutlined,
  PlusOutlined,
  ProfileOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { NeuroData } from "../constraints/deparatmentData";
import "../styles/globalStyle.css";
import {
  GetAllIndirectConsAction,
  GetAllInvoicesAction,
  PushInvoiceAction,
} from "../utils/action";
import { InvoiceUploadUrl } from "../utils/api";
const Consumption = () => {
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(false);
  const [invoicefile, setInvoiceFile] = useState([]);
  const [data, setData] = useState();
  const [okLoad, setOkLoad] = useState(false);
  const [drawerVis, setDrawerVis] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tableRef = useRef();
  const props = {
    name: "invoice",
    action: InvoiceUploadUrl(),
    headers: {
      authorization: "authorization-text",
    },
    maxCount: 1,
  };
  const {
    token: { colorBgContainer, colorPrimaryBg, colorText },
  } = theme.useToken();
  const onClose = () => {
    setDrawerVis(false);
  };
  const getIndirectConsData = async () => {
    setLoading(true);
    let data = await GetAllIndirectConsAction();
    setLoading(false);
    console.log(data, "htis is indirect");
    if (data) {
      setData(data.indirect);
      setCount(data.indirect.length);
      return true;
    }
  };
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setOkLoad(true);
    let data = await getIndirectConsData();
    setOkLoad(false);

    if (data) {
      setIsModalOpen(false);
      setInvoiceFile([]);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    console.log(tableRef.current);
    // tableRef.current.reload();
  };
  useEffect(() => {
    getIndirectConsData();
  }, []);
  const myColumns = [
    {
      title: "Organisation",
      dataIndex: "organisation",
      key: "organisation",
      width: 150,
      //   searchTextName: "Patient name",
      fixed: "left",
    },
    {
      title: "Document Number",
      key: "docNum",
      dataIndex: "docNum",
      width: 100,
    },
    {
      title: "Store Name",
      key: "storeName",
      dataIndex: "storeName",
      width: 150,
    },
    {
      title: "Item Code",
      key: "itemCode",
      dataIndex: "itemCode",
      width: 150,
    },
    {
      title: "Item Name",
      key: "itemName",
      dataIndex: "itemName",
      width: 250,
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
      width: 150,
    },
    {
      title: "Quantity",
      key: "qty",
      dataIndex: "qty",
      width: 100,
    },
    {
      title: "Cost",
      key: "cost",
      dataIndex: "cost",
      width: 100,
    },
    {
      title: "Total Cost",
      key: "totalCost",
      dataIndex: "totalCost",
      width: 100,
    },
    {
      key: "actions",
      title: "Actions",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip color={colorPrimaryBg} title="View">
              <Button
                shape="circle"
                style={{ marginRight: 6, marginBottom: 6 }}
                size="small"
                icon={<FolderViewOutlined />}
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ];
  const [selectedRowKeys, setSeletedRowKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSeletedRowKeys(selectedRowKeys);
    },
  };
  const apiRequest = () => {
    return new Promise(async (resolve) => {
      let data = await GetAllInvoicesAction();
      setData(data.data.invoice);
      setCount(data.data.invoice.length);
      console.log(data.data);
      resolve(data.data);
    });
  };

  return (
    <Content
      style={{
        margin: "0 16px",
      }}
    >
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>Indirect Consumption</Breadcrumb.Item>
      </Breadcrumb>

      <Card bordered={true}>
        <div className="ml-auto flex-row flex items-center justify-end">
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: colorPrimaryBg,
              marginRight: 10,
            }}
          >
            <DollarOutlined
              style={{ color: "#fff", marginRight: 5, fontSize: 18 }}
            />
            <text style={{ color: "#fff", fontWeight: "500" }}>REVENUE</text>
          </Button>
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: colorPrimaryBg,
            }}
            onClick={showModal}
          >
            <PlusOutlined
              style={{ color: "#fff", fontSize: 18, marginRight: 5 }}
            />
            <text style={{ color: "#fff", fontWeight: "500" }}>ADD</text>
          </Button>
        </div>

        {/* <TableComp
            ref={tableRef}
            columns={myColumns}
            extraProps={{ scroll: { x: 300 } }}
            apiRequest={() => apiRequest()}
            //   dataSource={NeuroData}
            pagination={{
              defaultPageSize: 10,
              pageSizeOptions: ["10", "25", "50", "100"],
              showSizeChanger: true,
            }}
          /> */}
        <Table
          ref={tableRef}
          columns={myColumns}
          scroll={{ x: 300 }}
          dataSource={data}
          loading={loading}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ["10", "25", "50", "100"],
            showSizeChanger: true,
          }}
        />
      </Card>
      <Drawer
        title="All Reports"
        width={500}
        placement="right"
        onClose={onClose}
        open={drawerVis}
      >
        <Timeline mode={"left"} style={{ padding: "20px" }}>
          <Timeline.Item>
            <a onClick={() => {}}>
              Blood Pressure by <b>{"Dr. Goel"}</b> on{" "}
              <b>{moment(new Date()).format("lll")}</b>
            </a>
          </Timeline.Item>
          <Timeline.Item>
            <a onClick={() => {}}>
              Diabetes by <b>{"Dr. Goel"}</b> on{" "}
              <b>{moment(new Date()).format("lll")}</b>
            </a>
          </Timeline.Item>
        </Timeline>
      </Drawer>
      <Modal
        title="Invoice Upload"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={okLoad}
        width={380}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Upload {...props} multiple={false}>
            <Button icon={<UploadOutlined />}>Upload Invoice</Button>
          </Upload>
        </div>
      </Modal>
    </Content>
  );
};

export default Consumption;
