export const AccountData = [
  {
    speciality: "Cardiac Surgery",
    department: [null],
    revenue: "2,815,563",
    mdirect: "16,375,421",
    ndirect: "14,755,144",
    direct: "999",
    deduction: "269,218",
  },
  {
    speciality: "Cardiology",
    department: [null],
    revenue: "13,822,838",
    mdirect: "80,150,421",
    ndirect: "72,366,144",
    direct: "2997",
    deduction: "305,218",
  },
  {
    speciality: "Critical Care",
    department: [null],
    revenue: "20,949,537",
    mdirect: "16,375,421",
    ndirect: "14,755,144",
    direct: "999",
    deduction: "269,218",
  },
  {
    speciality: "General Surgery & MAS",
    department: [null],
    revenue: "2,815,563",
    mdirect: "16,375,421",
    ndirect: "14,755,144",
    direct: "999",
    deduction: "269,218",
  },
  {
    speciality: "Internal Medicine",
    department: [null],
    revenue: "2,815,563",
    mdirect: "16,375,421",
    ndirect: "14,755,144",
    direct: "999",
    deduction: "269,218",
  },
  {
    speciality: "LTP & Surgical GI",
    department: [null],
    revenue: "2,815,563",
    mdirect: "16,375,421",
    ndirect: "14,755,144",
    direct: "999",
    deduction: "269,218",
  },
  {
    speciality: "Neurology",
    department: [null],
    revenue: "2,815,563",
    mdirect: "16,375,421",
    ndirect: "14,755,144",
    direct: "999",
    deduction: "269,218",
  },
  {
    speciality: "OBGY",
    department: [null],
    revenue: "2,815,563",
    mdirect: "16,375,421",
    ndirect: "14,755,144",
    direct: "999",
    deduction: "269,218",
  },
  {
    speciality: "Oncology",
    department: [null],
    revenue: "2,815,563",
    mdirect: "16,375,421",
    ndirect: "14,755,144",
    direct: "999",
    deduction: "269,218",
  },
  {
    speciality: "Orthopedics & Spine",
    department: [null],
    revenue: "2,815,563",
    mdirect: "16,375,421",
    ndirect: "14,755,144",
    direct: "999",
    deduction: "269,218",
  },
  {
    speciality: "Others",
    department: [null],
    revenue: "2,815,563",
    mdirect: "16,375,421",
    ndirect: "14,755,144",
    direct: "999",
    deduction: "269,218",
  },
  {
    speciality: "Renal Science",
    department: ["Nephrology", "Urology & Renal Transplant"],
    revenue: "2,815,563",
    mdirect: "16,375,421",
    ndirect: "14,755,144",
    direct: "999",
    deduction: "269,218",
  },
];
