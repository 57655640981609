import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Area } from "@ant-design/plots";
import { Data } from "../constraints/revenueData";
import { theme } from "antd";
const RevenueChart = (props) => {
  const [data, setData] = useState(Data);

  useEffect(() => {
    if (props.data && props.data.length > 0) {
      setData(props.data);
    }
  }, [props]);
  const {
    token: { colorText },
  } = theme.useToken();
  const config = {
    data: data,
    xField: "time",
    yField: "value",
    seriesField: "type",
    theme: {
      components: {
        legend: {
          common: {
            itemName: {
              style: {
                fill: colorText, //side label
              },
            },
          },
        },
      },
    },
  };

  return <Area {...config} />;
};
export default RevenueChart;
