import {
  Breadcrumb,
  Button,
  Card,
  Tag,
  theme,
  Tooltip,
  Drawer,
  Timeline,
  Table,
  Modal,
  Upload,
  Select,
  message,
  Form,
  Row,
  Col,
  Input,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useRef, useState } from "react";
import Sidebar from "../components/sidebar";
import { TableComp } from "sz-react-utils4";
import moment from "moment";
import {
  BookOutlined,
  DollarOutlined,
  IdcardOutlined,
  MessageOutlined,
  PlusOutlined,
  ProfileOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { NeuroData } from "../constraints/deparatmentData";
import "../styles/globalStyle.css";
import {
  GetAllInvoicesAction,
  PushInvoiceAction,
  GetAllDoctorsAction,
  ChangeInvoiceDoctorNameAction,
  CalculatDoctorPayoutAction,
  GetDoctorPayoutByDateAction,
} from "../utils/action";
import { UploadOutlined } from "@ant-design/icons";
import { InvoiceUploadUrl } from "../utils/api";
import { DatePicker, Space } from "antd";
import PaidChart from "../components/paidChart";
import RevenueChart from "../components/revenueChart";

const { RangePicker } = DatePicker;
const { Option } = Select;
const Invoice = () => {
  const [count, setCount] = useState();
  const [invoicefile, setInvoiceFile] = useState([]);
  const [showChangeNameModal, setShowChangeNameModal] = useState(false);
  const [invoiceId, setInvoiceId] = useState();
  const [options, setOptions] = useState([]);
  const [revenueData, setRevenueData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [data2, setData2] = useState();
  const [loading, setLoading] = useState(false);
  const [okLoad, setOkLoad] = useState(false);
  const [data, setData] = useState();
  const [drawerVis, setDrawerVis] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [statusModalVis, setStatusModalVis] = useState(false);
  const [filterOptions, setFilterOptions] = useState();
  const [singleInvoice, setSingleInvoice] = useState();
  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();
  const tableRef = useRef();

  const {
    token: { colorBgContainer, colorPrimaryBg, colorText },
  } = theme.useToken();
  const onClose = () => {
    setDrawerVis(false);
  };
  const props = {
    name: "invoice",
    action: InvoiceUploadUrl(),
    headers: {
      authorization: "authorization-text",
    },
    maxCount: 1,
  };
  const getInvoiceData = async () => {
    setLoading(true);
    let data = await GetAllInvoicesAction();
    setLoading(false);

    if (data.type) {
      return true;
    }
  };
  //revenue chart data
  useEffect(() => {
    let result = {};
    let result2 = {};
    if (data && data.data && data.data.length > 0) {
      ["Total Amount", "Net Amount"].map((ii, key) => {
        console.log(ii, "this is amount ii of foreach ");
        data.data.forEach((item) => {
          let v = moment(item.createdDateTime).format("DD/MM/YYYY");
          let obj = {
            time: v,
            type: ii,
            departmentName: item.departmentName,
          };

          if (ii === "Total Amount") {
            obj.value = item.totalAmt;
            if (result[v]) {
              result[v].value += obj.value;
            } else {
              result[v] = obj;
            }
          } else {
            obj.value = item.netAmt;
            if (result2[v]) {
              result2[v].value += obj.value;
            } else {
              result2[v] = obj;
            }
          }
        });
        console.log(Object.values(result));
        console.log(Object.values(result2));
        setRevenueData([...Object.values(result), ...Object.values(result2)]);
      });
    }
  }, [data]);
  const myColumns = [
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
      width: 150,
      fixed: "left",
      searchTextName: "Name",
      sorter: true,
    },
    {
      title: "Invoice_ID",
      dataIndex: "_id",
      key: "_id",
      width: 150,
    },
    {
      title: "Invoice Number",
      dataIndex: "invoiceNum",
      key: "invoiceNum",
      width: 170,
      render: (item) => {
        return <Tag>{item}</Tag>;
      },
    },

    {
      title: "UHID",
      dataIndex: "uhid",
      key: "uhid",
      width: 150,
    },
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
      width: 200,
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      width: 100,
    },
    {
      title: "Channel",
      key: "channel",
      dataIndex: "channel",
      width: 120,
      filters: ["TPA", "CGHS", "CASH", "INTERNATIONAL"].map((x) => {
        return { text: x, value: x };
      }),
      render: (item) => {
        return <Tag>{item}</Tag>;
      },
    },
    {
      title: "Actual Quantity",
      dataIndex: "actualQty",
      key: "actualQty",
      width: 150,
    },
    {
      title: "Department Name",
      dataIndex: "departmentName",
      key: "departmentName",
      width: 180,
      // searchTextName: "Department",
      filters: ["EHC", "PLASTIC SURGERY", "NEPHROLOGY"].map((x) => {
        return { text: x, value: x };
      }),
    },
    {
      title: "Consulting Doctor",
      dataIndex: "consultingDoctor",
      key: "consultingDoctor",
      width: 170,
      searchTextName: "Name",
      render: (_, record) => {
        return (
          <div>
            <p>{record.consultingDoctor}</p>
            <Button
              onClick={() => {
                form.setFieldsValue({
                  doctorId: `${record.consultingDoctor}`,
                });
                setSingleInvoice(record);
                setShowChangeNameModal(true);
                setInvoiceId(record._id);
              }}>
              <EditOutlined /> Edit
            </Button>
          </div>
        );
      },
    },
    {
      title: "Total Amount",
      key: "totalAmt",
      dataIndex: "totalAmt",
      searchTextName: "totalAmt",
      width: 120,
    },
    {
      title: "Net Amount",
      key: "netAmt",
      dataIndex: "netAmt",
      width: 120,
    },
    {
      title: "Created Date",
      sorter: true,
      key: "createdDateTime",
      dataIndex: "createdDateTime",
      width: 150,
      render: (val) => {
        if (val) return <Tag>{moment(val).format("lll")}</Tag>;
      },
    },
    {
      title: "Admission Date",
      key: "admissionDate",
      dataIndex: "admissionDate",
      width: 150,
      render: (val) => {
        if (val) return <Tag>{moment(val).format("lll")}</Tag>;
      },
    },
    {
      title: "Discharge Date",
      key: "dischargeDate",
      dataIndex: "dischargeDate",
      width: 150,
      render: (val) => {
        if (val) return <Tag>{moment(val).format("lll")}</Tag>;
      },
    },
    {
      title: "Discharge Type",
      key: "dischargeType",
      dataIndex: "dischargeType",
      width: 120,
      render: (val) => {
        if (val) return <Tag>{val}</Tag>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip color={colorPrimaryBg} title="Add Query">
              <Button
                shape="circle"
                style={{ marginRight: 6, marginBottom: 6 }}
                size="small"
                icon={<MessageOutlined />}
              />
            </Tooltip>
            <Tooltip color={colorPrimaryBg} title="Change Status">
              <Button
                onClick={() => setStatusModalVis(true)}
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<BookOutlined />}
              />
            </Tooltip>
            <Tooltip color={colorPrimaryBg} title="View Report">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<ProfileOutlined />}
                onClick={() => {
                  setDrawerVis(true);
                }}
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ];
  const [selectedRowKeys, setSeletedRowKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSeletedRowKeys(selectedRowKeys);
    },
  };
  useEffect(() => {
    tableRef.current.reload();
  }, [startDate, endDate]);
  const apiRequest = (params) => {
    console.log(params, "ths is invoice serarch could be!");
    return new Promise(async (resolve) => {
      if (!params.sortField) {
        params.sortField = "createdDateTime";
        params.sortOrder = "descend";
      }
      let obj = {
        results: 10,
        count: 10,
        sortField: "createdDateTime",
        sortOrder: "ascend",
      };
      if (startDate && endDate) {
        params.createdDateTime = {
          $lte: moment(new Date(endDate)).endOf("day").format(),
          $gte: moment(new Date(startDate)).startOf("day").format(),
        };
        obj.createdDateTime = {
          $lte: moment(new Date(endDate)).endOf("day").format(),
          $gte: moment(new Date(startDate)).startOf("day").format(),
        };
      }

      let { data } = await GetAllInvoicesAction({
        ...params,
        regExFilters: ["patientName", "consultingDoctor"],
      });

      let dataAll = await GetAllInvoicesAction(obj);
      console.log(dataAll);
      setData(dataAll.data);
      resolve(data);
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setOkLoad(true);
    let { data } = await GetAllInvoicesAction();
    setOkLoad(false);

    if (data.type) {
      setIsModalOpen(false);
      setInvoiceFile([]);
      tableRef.current.reload();
    }
  };
  const handleOkStatus = async () => {
    setStatusModalVis(false);
    messageApi.open({
      type: "success",
      content: "Status Changed Successfully!",
    });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setStatusModalVis(false);
    console.log(tableRef.current);
    // tableRef.current.reload();
  };
  const getDoctorData = async () => {
    const { data } = await GetAllDoctorsAction({ count: 0, results: 0 });
    console.log(data);
    if (data) {
      const doctors = data.data;

      const doctorOptions = doctors.map((doctor) => ({
        value: doctor._id,
        label: `${doctor.name}`,
      }));

      setOptions(doctorOptions);
    }
  };
  // for doctor option in the select feild
  useEffect(() => {
    getDoctorData();
  }, []);

  // handel the search in the doctorNameChange model
  const handleSearch = (value) => {
    const filteredOptions = options.filter((option) => {
      const label = option.label.toLowerCase();
      value = value.toLowerCase();
      return (
        label.includes(value) || // Search by name
        option.value.includes(value) // Search by ID
      );
    });

    // console.log("Search value:", value);
    // console.log("Filtered options:", filteredOptions);
    setFilterOptions(filteredOptions);
  };

  // restore the option array when there is no search result
  useEffect(() => {
    if (filterOptions?.length === 0 && options.length > 0) {
      setFilterOptions(options);
    }
  }, [options]);

  const handleDoctorChangeName = (value) => {
    console.log(value);
    const sendChangedDoctorName = async () => {
      try {
        // action to change the consultan doctor name
        await ChangeInvoiceDoctorNameAction({
          invoiceId: invoiceId,
          doctorId: value.doctorId,
        });

        // update the doctors payout according to changes in the invoice
        await CalculatDoctorPayoutAction();

        // get the update invoice
        tableRef.current.reload();

        setShowChangeNameModal(false);
      } catch (err) {
        setShowChangeNameModal(false);
        console.log(err);
      }
    };
    sendChangedDoctorName();
    console.log(invoiceId);
  };
  return (
    <Content
      style={{
        margin: "0 16px",
      }}>
      {contextHolder}
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}>
        <Breadcrumb.Item>Invoices</Breadcrumb.Item>
      </Breadcrumb>

      <Card bordered={true}>
        <div className={"flex flex-row justify-between items-center"}>
          <RangePicker
            format={"DD/MM/YYYY"}
            onChange={(date) => {
              if (date) {
                const [start, end] = date;
                console.log(start, "  lsafd ksa", end);
                setStartDate(start);
                setEndDate(end);
              } else {
                setStartDate(null);
                setEndDate(null);
              }
            }}
          />

          <div className="ml-auto flex-row flex items-center justify-end">
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: colorPrimaryBg,
                marginRight: 10,
              }}>
              <DollarOutlined
                style={{ color: "#fff", marginRight: 5, fontSize: 18 }}
              />
              <text style={{ color: "#fff", fontWeight: "500" }}>REVENUE</text>
            </Button>
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: colorPrimaryBg,
              }}
              onClick={showModal}>
              <PlusOutlined
                style={{ color: "#fff", fontSize: 18, marginRight: 5 }}
              />
              <text style={{ color: "#fff", fontWeight: "500" }}>ADD</text>
            </Button>
          </div>
        </div>

        <TableComp
          ref={tableRef}
          columns={myColumns}
          extraProps={{ scroll: { x: 300 } }}
          loading={loading}
          // dataSource={data}
          apiRequest={apiRequest}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ["10", "25", "50", "100"],
            showSizeChanger: true,
          }}
        />
        {/* <Table
          ref={tableRef}
          columns={myColumns}
          scroll={{ x: 300 }}
          dataSource={data}
          loading={loading}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ["10", "25", "50", "100"],
            showSizeChanger: true,
          }}
        /> */}
      </Card>
      <Card className="mt-3">
        <RevenueChart data={revenueData} />
      </Card>
      <Drawer
        title="All Reports"
        width={500}
        placement="right"
        onClose={onClose}
        open={drawerVis}>
        <Timeline mode={"left"} style={{ padding: "20px" }}>
          <Timeline.Item>
            <a onClick={() => {}}>
              Blood Pressure by <b>{"Dr. Goel"}</b> on{" "}
              <b>{moment(new Date()).format("lll")}</b>
            </a>
          </Timeline.Item>
          <Timeline.Item>
            <a onClick={() => {}}>
              Diabetes by <b>{"Dr. Goel"}</b> on{" "}
              <b>{moment(new Date()).format("lll")}</b>
            </a>
          </Timeline.Item>
        </Timeline>
      </Drawer>
      <Modal
        title="Invoice Upload"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={okLoad}
        width={380}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Upload {...props} multiple={false}>
            <Button icon={<UploadOutlined />}>Upload Invoice</Button>
          </Upload>
        </div>
      </Modal>
      <Modal
        title="Change Status"
        open={statusModalVis}
        onOk={handleOkStatus}
        onCancel={handleCancel}
        width={380}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Select
            placeholder="Select Status"
            style={{
              width: 200,
            }}
            // onChange={(label) => }
            options={[
              {
                value: "Approve",
                label: "Approve",
              },
              {
                value: "Reject",
                label: "Reject",
              },
              {
                value: "Send to Admin for review",
                label: "Send to Admin for review",
              },
            ]}
          />
        </div>
      </Modal>
      <Modal
        title="Change Consulting Doctor"
        open={showChangeNameModal}
        width={500}
        footer={null}
        onCancel={() => setShowChangeNameModal(false)}>
        <div className="w-full text-center">
          <p>
            Invoice Number:{" "}
            <Tag>{singleInvoice && singleInvoice.invoiceNum}</Tag>{" "}
          </p>
        </div>
        <Form form={form} onFinish={handleDoctorChangeName}>
          <Form.Item
            name="doctorId"
            label="Doctor"
            rules={[
              {
                required: true,
                message: "Please enter Doctor",
              },
            ]}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}>
            <Select
              style={{
                width: "100%",
              }}
              showSearch
              placeholder="Search by ID or Name"
              optionFilterProp="label"
              filterOption={false}
              onSearch={handleSearch}
              options={filterOptions}>
              {options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {/* {showError && <p className={classes.error}>{errorMsg}</p>} */}
          <Row justify="center" align="middle">
            <Col>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Content>
  );
};

export default Invoice;
