import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Pie } from "@ant-design/plots";
import { theme } from "antd";

const PieChart = (props) => {
  const [data, setData] = useState([
    { type: "PLASTIC SURGERY", value: 31 },
    { type: "NEPHROLOGY", value: 49 },
    { type: "EHC", value: 21 },
  ]);
  const {
    token: { colorText },
  } = theme.useToken();
  const config = {
    theme: {
      components: {
        legend: {
          common: {
            itemName: {
              style: {
                fill: colorText || "#000", //side label
              },
            },
          },
        },
      },
    },
    appendPadding: 5,
    data,

    angleField: "value",
    colorField: "type",
    // color: ["#de0aff", "#0a12ff", "#0aff1b", "#ffad0a"],
    radius: 0.8,
    label: {
      type: "outer",
      offset: "-10%",
      content: "{name} {percentage}",
      style: {
        fill: colorText || "#000", //inner label
      },
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  };
  useEffect(() => {
    console.log(props.data, "tjh sis is props");
    if (props.data && props.data.length > 0) {
      setData(props.data);
    }
  }, [props]);
  return <Pie {...props} {...config} />;
};

export default PieChart;
