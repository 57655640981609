import React, { useState } from "react";
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("Dashboard", "home", <PieChartOutlined />),
  //   getItem("Option 2", "2", <DesktopOutlined />),
  getItem("Department", "department", <FileOutlined />, [
    getItem("Listing", "listing"),
    getItem("Add Listing", "4"),
  ]),
  getItem("Department", "sub2", <TeamOutlined />, [
    getItem("Listing", "6"),
    getItem("Add Listing", "8"),
  ]),
  getItem("Account", "9", <UserOutlined />),
];
const Sidebar = () => {
  const [url, setUrl] = useState();
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const onClick = (e) => {
    console.log("click ", e.keyPath[0]);
    let uri =
      e.keyPath.length > 1
        ? `/${e.keyPath[1]}/${e.keyPath[0]}`
        : `/${e.keyPath[0]}`;
    setUrl([e.keyPath[0]]);
    navigate(uri);
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <div
          style={{
            height: 32,
            margin: 16,
            background: "rgba(255, 255, 255, 0.2)",
          }}
        />
        <Menu
          onClick={onClick}
          theme="dark"
          defaultSelectedKeys={[url]}
          // selectedKeys={[url]}
          mode="inline"
          items={items}
        />
      </Sider>
      <Layout className="site-layout">
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
            paddingLeft: 10,
            color: "#000",
            fontSize: 20,
          }}
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger",
              onClick: () => setCollapsed(!collapsed),
            }
          )}
        </Header>
        <Content
          style={{
            margin: "0 16px",
          }}
        >
          <Breadcrumb
            style={{
              margin: "16px 0",
            }}
          >
            <Breadcrumb.Item>User</Breadcrumb.Item>
            <Breadcrumb.Item>Bill</Breadcrumb.Item>
          </Breadcrumb>
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            Bill is a cat.
          </div>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          Ant Design ©2018 Created by Ant UED
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Sidebar;
