import { ConfigProvider, notification } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./containers/dashboard";
import Login from "./containers/login";
import Listing from "./containers/listing";
import React, { useEffect, useMemo, useState } from "react";
import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LoginOutlined,
  BellFilled,
  InfoCircleFilled,
} from "@ant-design/icons";
import { Affix } from "antd";
import { Breadcrumb, Layout, Menu, theme, Tag } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import jaypee_logo from "./assets/jaypee_logo.png";
import moon from "./assets/moon.png";
import sun from "./assets/sun.png";
import OrthoList from "./containers/orthoLIst";
import InterMedList from "./containers/interMedList";
import Accounts from "./containers/account";
import Invoice from "./containers/invoice";
import Consumption from "./containers/consumption";
import Allocation from "./containers/allocation";
import FinalReport from "./containers/finalReport";
import Doctors from "./containers/doctors";
import ServiceItems from "./containers/serviceItems";
import AddServiceItem from "./components/addServiceItem";
import Groups from "./containers/group";
import GroupDetails from "./components/groupDetails";
import AddDoctor from "./components/addDoctor";
import EditDoctor from "./components/EditDoctor";
import EditServiceItem from "./components/EditServiceItem";
const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}
const items = [
  getItem("Dashboard", "home", <PieChartOutlined />),
  //   getItem("Option 2", "2", <DesktopOutlined />),
  getItem("Departments", "departments", <FileOutlined />, [
    getItem("Orthopaedics", "orthopaedics"),
    getItem("Internal Medicine", "internalMedicine"),
    getItem("Nephrology", "nephrology"),
  ]),
  getItem("Consumption", "consumption", <FileOutlined />),

  getItem("Invoice", "invoice", <UserOutlined />),
  getItem("Allocation", "allocation", <FileOutlined />),
  getItem("Final Report", "report", <FileOutlined />),
  getItem("All Services", "serviceItems", <FileOutlined />),
  getItem("All Doctors", "doctors", <UserOutlined />),
  getItem("All Groups", "groups", <UserOutlined />),

  // getItem("Account", "account", <UserOutlined />),
];

const App = () => {
  const [checkLogin, setCheckLogin] = useState(false);
  const [url, setUrl] = useState("home");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const onClick = (e) => {
    console.log("click ", url);
    let uri = e.keyPath.length > 1 ? `/${e.keyPath[0]}` : `/${e.keyPath[0]}`;
    setUrl([e.keyPath[0]]);

    navigate(uri);
  };
  const getLogin = () => {
    let data = localStorage.getItem("jaypee");
    if (data) {
      setCheckLogin(true);
      navigate("/home");
    } else {
      navigate("/");
    }
  };
  const logoutFunc = () => {
    localStorage.removeItem("jaypee");
    window.location.reload(false);
  };
  useEffect(() => {
    if (checkLogin === false) getLogin();
  }, [checkLogin]);
  const customDark = {
    colorPrimary: "#f28021",
    colorPrimaryBg: "#f28021",
    colorLink: "#f28021",
    colorBgContainer: "#001529",
    // colorBgBase: "#f3a662",
    colorBgBase: "#33345e",
    colorText: "#fff",
    colorPrimaryTextActive: "#000",
    colorTextBase: "#fff",
    colorBgTextActive: "#000",
    colorPrimaryText: "#fff",
    colorLinkHover: "#f28021",
    colorFillContentHover: "#f28021",
    colorPrimaryBgHover: "#f28021",

    colorLinkActive: "#000",
  };
  const customDefault = {
    colorPrimary: "#f28021",
    colorPrimaryBg: "#f28021",
    colorLink: "#f28021",
    colorLinkHover: "#000",
    colorPrimaryTextActive: "#000",
    // colorLinkHover: "#f28021",
    colorFillContentHover: "#f28021",
    colorPrimaryBgHover: "#f28021",
    colorLinkActive: "#000",
    colorTextBase: "#000",
    colorText: "#000",
    // colorBgTextActive: "#fff",
  };
  const mytheme = isDarkMode ? customDark : customDefault;

  const openNotification = (placement) => {
    api.info({
      duration: 2,
      message: `You have received a notificaton from Admin!`,
      // description: (
      //   <Context.Consumer>{({ name }) => `Hello, ${name}!`}</Context.Consumer>
      // ),
      icon: <InfoCircleFilled style={{ color: mytheme.colorPrimary }} />,
      placement,
    });
  };

  return (
    <div className="App">
      {contextHolder}
      <ConfigProvider
        theme={{
          token: {
            ...mytheme,
          },
          components: {},
        }}
      >
        {checkLogin ? (
          <Layout
            style={{
              minHeight: "100vh",
              overflow: "hidden",
            }}
          >
            <Affix offsetTop={0}>
              <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                style={{
                  minHeight: "100vh",
                }}
              >
                <div className="flex justify-center items-center">
                  <img
                    className="h-10 w-22 ml-auto mr-auto mt-3 mb-2"
                    src={jaypee_logo}
                    alt="jaypee_logo"
                  />
                </div>

                <Menu
                  onClick={onClick}
                  theme="dark"
                  defaultSelectedKeys={[url]}
                  // selectedKeys={[url]}
                  mode="inline"
                  items={items}
                  // style={{ background: "orange" }}
                />
              </Sider>
            </Affix>
            <Layout className="site-layout">
              <Header
                style={{
                  padding: 0,
                  background: "#001529",
                  paddingLeft: 10,
                  color: "#fff",
                  fontSize: 20,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: () => setCollapsed(!collapsed),
                  }
                )}
                <Tag
                  color="#fcac5c"
                  style={{
                    marginLeft: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <UserOutlined style={{ fontSize: 15 }} />
                  <span
                    style={{
                      marginLeft: 5,
                      fontSize: 14,
                      textTransform: "uppercase",
                    }}
                    className="font-medium"
                  >
                    {" "}
                    Admin@gmail.com
                  </span>
                </Tag>
                <div className="ml-auto flex flex-row items-center">
                  <BellFilled
                    onClick={() => openNotification("bottomRight")}
                    className="mr-2"
                  />
                  {isDarkMode ? (
                    <button
                      onClick={() => setIsDarkMode(false)}
                      className="h-8 w-8 p-1 bg-transparent border-none rounded-full cursor-pointer"
                    >
                      <img className="h-6 w-6" src={sun} alt="theme_change" />
                    </button>
                  ) : (
                    <button
                      onClick={() => setIsDarkMode(true)}
                      className=" bg-white pr-1 pt-1 pl-1 rounded-full cursor-pointer"
                    >
                      <img
                        className="h-5 w-5 bg-white rounded-full"
                        src={moon}
                        alt="theme_change"
                      />
                    </button>
                  )}

                  <Menu
                    style={{
                      marginRight: 20,
                      background: "#001529",
                    }}
                    onClick={logoutFunc}
                  >
                    <Menu.Item>
                      <LoginOutlined
                        style={{ color: "#fff", fontWeight: "bolder" }}
                      />
                      <span style={{ color: "#fff" }}>Logout</span>
                    </Menu.Item>
                  </Menu>
                </div>
              </Header>
              <Content
                style={{
                  margin: "0 16px",
                }}
              >
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/home" element={<Dashboard />} />
                  <Route path="/orthopaedics" element={<OrthoList />} />
                  <Route path="/internalMedicine" element={<InterMedList />} />
                  <Route path="/nephrology" element={<Listing />} />
                  <Route path="/invoice" element={<Invoice />} />
                  <Route path="/consumption" element={<Consumption />} />
                  <Route path="/allocation" element={<Allocation />} />
                  <Route path="/report" element={<FinalReport />} />
                  <Route path="/account" element={<Accounts />} />
                  <Route path="/doctors" element={<Doctors />} />
                  <Route path="/doctors/new" element={<AddDoctor />} />
                  <Route
                    path="/doctors/edit/:doctorId"
                    element={<EditDoctor />}
                  />
                  <Route path="/serviceItems" element={<ServiceItems />} />
                  <Route
                    path="/serviceItems/new"
                    element={<AddServiceItem />}
                  />
                  <Route
                    path="/serviceItems/edit/:serviceId"
                    element={<EditServiceItem />}
                  />
                  <Route path="/groups" element={<Groups />} />
                  <Route path="/groups/:groupId" element={<GroupDetails />} />
                </Routes>
              </Content>
            </Layout>
          </Layout>
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        )}
      </ConfigProvider>
    </div>
  );
};

export default App;
