import { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Collapsible from "react-collapsible";
import { CopyToClipboard } from "react-copy-to-clipboard";
import downArrow from "../assets/down_arrow.png";
import upArrow from "../assets/up_arrow.png";

import user from "../assets/users/user-1.jpg";
import defaultUser from "../assets/users/default.jpg";
import "../styles/customAnimation.css";
import classes from "../styles/groupDetails.module.css";
import {
  DeleteGroupAction,
  GetOneGroupsAction,
  DeleteDoctorFromGroupAction,
  AddDoctorInGroupAction,
  GetAllDoctorsAction,
  UpdateGroupTotalPayoutAction,
  UpdateDoctorGroupCommissionAction,
  EditGroupNameAction,
  ChangeDoctorGroupStatusAction,
} from "../utils/action";

import {
  Card,
  Button,
  Modal,
  Form,
  Input,
  theme,
  Select,
  Row,
  Col,
  Tooltip,
  InputNumber,
  message,
  Badge,
  Avatar,
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  EditOutlined,
  CloseOutlined,
  CheckOutlined,
  DownOutlined,
  MailFilled,
  UpOutlined,
  TeamOutlined,
  DeleteFilled,
  FileDoneOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import GroupStatus from "./GroupStatus";
import { Collapse } from "react-collapse";
import _ from "lodash";
const { Option } = Select;

//  groupStatus: ["NA", "pending", "flag", "approved", "rejected"],
const groupStatusOptions = [
  {
    label: "Approved",
    value: "approved",
  },
  { label: "Reject", value: "rejected" },
  { label: "Flag", value: "flag" },
  { label: "Pending", value: "pending" },
];

const GroupDetails = () => {
  const [group, setGroup] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [removeModelVisible, setRemoveModelVisible] = useState(false);
  const [addModelVisible, setAddModelVisible] = useState(false);
  const [payoutModelVisible, setPayoutModelVisible] = useState(false);
  const [commissionModelVisible, setCommissionModelVisible] = useState(false);
  const [statusModelVisible, setStatusModelVisible] = useState(false);
  const [showGroupEditForm, setShowGroupEditForm] = useState(false);
  const [editGroupError, setEditGroupError] = useState(null);
  const [showEditGroupError, setShowEditGroupError] = useState(false);
  const [showDoctorInfo, setShowDoctorInfo] = useState(false);
  const [expandedDoctorId, setExpandedDoctorId] = useState(null);
  const [options, setOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState();
  const [doctorId, setDoctorId] = useState();
  const [groupName, setGroupName] = useState();
  const params = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [newVal, setNewVal] = useState();
  const [addModelError, setAddModelError] = useState(null);
  const [updateDoctorGroupCommModelError, setUpdateDoctorGroupCommModelError] =
    useState(null);
  const groupId = params.groupId;
  const [expand, setExpand] = useState(false);
  const [allGrpDoc, setAllGrpDoc] = useState();
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);

  const [collapse, setCollapse] = useState(false);

  const handleDoctorClick = (doctorId) => {
    setSelectedDoctorId((prevDoctorId) =>
      prevDoctorId === doctorId ? null : doctorId
    );
  };

  const {
    token: { colorPrimaryBg },
  } = theme.useToken();

  // reset the scrolling in the page
  useEffect(() => {
    // Save the scroll position in session storage when component mounts
    sessionStorage.setItem("scrollPosition", window.pageYOffset);

    // Reset the scroll position to the top when component mounts
    window.scrollTo(0, 0);

    // Restore the scroll position when the component is unmounted
    return () => {
      const scrollPosition = sessionStorage.getItem("scrollPosition");
      if (scrollPosition) {
        window.scrollTo(0, parseInt(scrollPosition));
      }
      sessionStorage.removeItem("scrollPosition");
    };
  }, []);

  const getGroupData = async () => {
    const { data } = await GetOneGroupsAction(groupId);
    if (data && data.data) {
      setGroup(data.data.group);
      console.log(data.data.group, "this sis data group");
      let { doctors } = data.data.group;
      setAllGrpDoc(doctors.map((item) => ({ ...item, selected: false })));
    }
  };

  //GET THE GROUP DATA FROM DATABASE
  useEffect(() => {
    getGroupData();
  }, []);

  //DELETE GROUP
  const handleDelete = () => {
    // Performing the deletion logic
    console.log(groupId);
    const deleteGroup = async () => {
      let data = await DeleteGroupAction(groupId);

      // navigate to group page
      if (data) {
        navigate("/groups");
      }
    };
    deleteGroup();
    // Hide the modal
    setModalVisible(false);
  };

  //REMOVE THE DOCTOR FROM THE GROUP
  const handleRemove = async () => {
    // Performing the removing  logic
    const data = await DeleteDoctorFromGroupAction(doctorId);
    if (data) {
      setRemoveModelVisible(false);
      getDoctorData();
      return getGroupData();
    }
  };

  // ADD DOCTOR IN GROUP
  const handlerAddDoctor = async (value) => {
    try {
      const { data } = await AddDoctorInGroupAction(groupId, value.doctorId);
      if (data.success) {
        setAddModelVisible(false);
        setAddModelError(null);
        getDoctorData();
        return getGroupData();
      }
    } catch (err) {
      console.log("err", err.response.data.message);
      setAddModelVisible(true);
      setAddModelError(err.response.data.message);
    }

    // form.resetFields();
    // return getGroupData();
  };

  // EDIT THE GROUP NAME
  const handlerEditGroupName = async (value) => {
    console.log(value, "this is value");
    try {
      const data = await EditGroupNameAction({
        groupId,
        newGroupName: value,
      });
      console.log(data);
      setShowGroupEditForm(false);
      setEditGroupError(null);
      setShowEditGroupError(false);
      getGroupData();
    } catch (err) {
      console.log(err);
      console.log(err.response.data.message);
      setShowGroupEditForm(true);
      setShowEditGroupError(true);
      setEditGroupError(err.response.data.message);
    }
  };

  // update the total group payout
  const handlerGroupTotalPayout = async (value) => {
    console.log(value);
    // send the new group total payout to the server
    const { data } = await UpdateGroupTotalPayoutAction({
      groupId: groupId,
      totalPayout: value.totalPayout,
    });
    if (data.success) {
      setPayoutModelVisible(false);
      getGroupData();
    } else {
      console.log("somthing went wrong in updating group total payout");
    }
  };
  // UPDATE DOCTOR COMMISSION IN GROUP
  const handlerDoctorGroupComm = async (value) => {
    try {
      const { data } = await UpdateDoctorGroupCommissionAction({
        doctorId,
        groupComm: value.groupComm,
      });
      if (data.success) {
        setCommissionModelVisible(false);
        setUpdateDoctorGroupCommModelError(null);
        getGroupData();
      }
    } catch (err) {
      setUpdateDoctorGroupCommModelError(err.response.data.message);
      setCommissionModelVisible(true);
    }
  };

  // CHANGE THE DOCTOR GROUP STATUS
  const handelStatusSubmit = async (value) => {
    console.log("status", value);
    // send the changed status to the backend
    const { data } = await ChangeDoctorGroupStatusAction({
      doctorId,
      groupStatus: value.status,
    });

    if (data.success) {
      setStatusModelVisible(false);
      messageApi.open({
        type: "success",
        content: "Status Changed Successfully!",
      });
      getGroupData();
    } else {
      console.log("error updating status");
    }
  };

  // for doctor option in the select feild
  const getDoctorData = async () => {
    const { data } = await GetAllDoctorsAction({ results: 0, count: 0 });
    console.log(data);
    if (data) {
      const doctors = data.data;
      const filterDoctors = doctors.filter(
        (doctor) =>
          !doctor.groupDetails.group || doctor.groupDetails.group === null
      );

      // console.log("filter doctor", filterDoctors);
      const doctorOptions = filterDoctors.map((doctor) => ({
        value: doctor._id,
        label: `${doctor.name}(${doctor._id})`,
      }));

      setOptions(doctorOptions);
    }
  };
  useEffect(() => {
    getDoctorData();
  }, []);

  // handel the search in the doctorNameChange model
  const handleSearch = (value) => {
    const filteredOptions = options.filter((option) => {
      const label = option.label.toLowerCase();
      value = value.toLowerCase();
      return (
        label.includes(value) || // Search by name
        option.value.includes(value) // Search by ID
      );
    });
    setFilterOptions(filteredOptions);
  };

  // restore the option array when there is no search result
  useEffect(() => {
    if (filterOptions?.length === 0 && options.length > 0) {
      setFilterOptions(options);
    }
  }, [options]);

  return (
    <Fragment>
      {contextHolder}
      {group && (
        <div className="w-full h-fit mt-4 border-2 rounded-md p-4 pl-6 pt-5 shadow-md shadow-black">
          <div className="flex flex-row ">
            <div className="flex-1">
              {!showGroupEditForm && (
                <div className="">
                  <Avatar size={64} icon={<TeamOutlined />} />
                  {/* <i className="fa-solid fa-users-rectangle"></i> */}
                  <span className="font-bold ml-3 text-xl">
                    {group?.name.toUpperCase()}
                  </span>
                  <span className="ml-1">
                    <Tooltip color={colorPrimaryBg} title="Edit GroupName">
                      <EditOutlined
                        onClick={() => {
                          // form.setFieldsValue({
                          //   groupName: group?.name
                          // })
                          setShowGroupEditForm(true);
                        }}
                      />
                    </Tooltip>
                  </span>
                </div>
              )}
              {showGroupEditForm && (
                <div className="flex flex-row items-center">
                  <Avatar size={64} icon={<TeamOutlined />} />

                  <Input
                    className="ml-2"
                    style={{ width: "150px" }}
                    placeholder="Enter Group Name"
                    defaultValue={group?.name}
                    value={groupName}
                    onChange={(val) => setGroupName(val.target.value)}
                  />
                  <div>
                    <Button
                      className={classes.checkBtn}
                      onClick={() => {
                        if (groupName) {
                          handlerEditGroupName(groupName);
                          setShowGroupEditForm(false);
                          setEditGroupError(null);
                          setShowEditGroupError(false);
                          form.resetFields();
                        }
                      }}
                      shape="circle"
                      icon={<CheckOutlined />}
                    />
                    <Button
                      className={classes.closeBtn}
                      shape="circle"
                      icon={<CloseOutlined />}
                      onClick={() => {
                        setShowGroupEditForm(false);
                        setEditGroupError(null);
                        setShowEditGroupError(false);
                        form.resetFields();
                      }}
                    />
                  </div>
                  {showEditGroupError && editGroupError && (
                    <p className={classes.groupNameError}>{editGroupError}</p>
                  )}
                </div>
              )}

              <p className={classes.groupId}>
                Group ID:<span>{group._id}</span>
              </p>
              <div className={"flex flex-row justify-start items-center mt-2"}>
                <span className={classes.groupPayoutHead}>
                  Total Group Members:
                </span>
                <span style={{ marginLeft: "10px" }}></span>
                <Badge count={group.doctors.length}>
                  <TeamOutlined style={{ fontSize: "20px" }} />
                </Badge>
              </div>
              <div className={"flex flex-row justify-start items-center mt-2"}>
                <span className={classes.groupPayoutHead}>
                  Total Group Payout:
                </span>
                <span className={"text-lg font-semibold mr-1"}>₹</span>
                <span
                  className={
                    "text-lg font-semibold bg-blue-600 rounded-md p-1 ml-0 mr-2"
                  }
                >
                  {group?.totalGroupPayout}
                </span>

                <Tooltip color={colorPrimaryBg} title="Assign Payout">
                  <EditOutlined
                    onClick={() => {
                      setNewVal(group?.totalGroupPayout);
                      form.setFieldsValue({
                        totalPayout: group?.totalGroupPayout,
                      });
                      setPayoutModelVisible(true);
                    }}
                  />
                </Tooltip>
              </div>
            </div>
            <div>
              <Tooltip color={colorPrimaryBg} title="Delete Group">
                <Button
                  className={"rounded-full bg-red-500 h-12"}
                  onClick={() => setModalVisible(true)}
                >
                  <span className="flex-1">
                    <DeleteOutlined
                      style={{ color: "#fff", fontSize: "20px" }}
                    />
                  </span>
                </Button>
              </Tooltip>
            </div>
          </div>

          <div className={"mt-9"}>
            <div className="flex flex-row items-center justify-center">
              <div className="mb-0 text-2xl font-bold mr-2 text-gray-500">
                Doctors Information
              </div>

              <Tooltip title="Add New Doctor" color={colorPrimaryBg}>
                <Button
                  className={
                    "rounded-full bg-green-500 h-9 flex items-center px-3"
                  }
                  onClick={() => {
                    setAddModelVisible(true);
                  }}
                >
                  <PlusOutlined
                    style={{
                      color: "#fff",
                      fontSize: "15px",
                      flex: 1,
                    }}
                  />
                </Button>
              </Tooltip>
            </div>
            <div className="mt-5">
              {allGrpDoc &&
                allGrpDoc.map((doctor) => {
                  return (
                    <>
                      <div className="mb-3">
                        <div
                          className={
                            doctor.selected
                              ? "rounded-t-3xl bg-white relative z-20"
                              : "rounded-full bg-white shadow-md roundAnim relative z-20"
                          }
                          key={doctor._id}
                        >
                          <div className="flex flex-row">
                            <div className="p-2">
                              <img
                                className="flex items-center border-2 border-gray-700 border-solid ml-1 w-14 rounded-full"
                                // src="https://xsgames.co/randomusers/avatar.php?g=pixel"
                                src={defaultUser}
                                alt={doctor.name}
                              />
                            </div>
                            <div className="flex flex-col justify-center">
                              <h3 className="my-0 py-0">{doctor.name}</h3>
                              <p className="text-xs font-bold text-gray-600">
                                {doctor.department.toUpperCase()}
                              </p>
                            </div>
                            <div
                              className={
                                "flex justify-center items-center mb-4 ml-1"
                              }
                            >
                              <span className="groupComm px-1 rounded-md text-base">
                                {doctor.groupDetails.groupComm}%
                              </span>
                              <EditOutlined
                                onClick={() => {
                                  form.setFieldsValue({
                                    groupComm: doctor.groupDetails.groupComm,
                                  });
                                  setDoctorId(doctor._id);
                                  setCommissionModelVisible(true);
                                }}
                              />
                            </div>
                            <div
                              className={
                                "flex flex-row items-center justify-end  ml-auto mr-8"
                              }
                            >
                              <GroupStatus
                                status={doctor.groupDetails.groupStatus}
                              />
                              <EditOutlined
                                onClick={() => {
                                  form.setFieldsValue({
                                    status: doctor.groupDetails.groupStatus,
                                  });
                                  setDoctorId(doctor._id);
                                  setStatusModelVisible(true);
                                }}
                              />
                              <img
                                src={doctor.selected ? downArrow : upArrow}
                                alt="up arrow"
                                className={"w-4 ml-5 cursor-pointer"}
                                onClick={() => {
                                  console.log("running!");
                                  setAllGrpDoc(
                                    allGrpDoc.map((item) => {
                                      if (item._id === doctor._id) {
                                        return {
                                          ...item,
                                          selected: !item.selected,
                                        };
                                      } else {
                                        return { ...item };
                                      }
                                    })
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <Collapse
                          className="ReactCollapse--collapse"
                          isOpened={doctor.selected}
                        >
                          <div className="bg-white p-2 pb-0 shadow-md relative z-10 rounded-b-md">
                            <div className="flex flex-row justify-start items-center ">
                              <div
                                className={"ml-5 text-sm font-semibold flex-1"}
                              >
                                <MailFilled className="text-lg text-red-700 mr-1" />
                                {doctor.email.toUpperCase()}
                              </div>
                              <div className={"flex-1 text-left"}>
                                <span className={"text-xl font-bold"}>₹</span>
                                <span className={`${classes.money}`}>
                                  {doctor.groupPayout}
                                </span>
                              </div>
                              <DeleteFilled
                                style={{ flex: 0.1 }}
                                className="justify-end text-red-700 text-xl ml-auto mr-5 "
                                onClick={() => {
                                  setRemoveModelVisible(true);
                                  setDoctorId(doctor._id);
                                  setExpandedDoctorId(null);
                                  setShowDoctorInfo(false);
                                }}
                              />
                            </div>
                            <div
                              className={
                                "flex flex-row items-center justify-center mt-4 pb-2"
                              }
                            >
                              <div className="mr-1">ID: {doctor._id}</div>
                              <CopyToClipboard text={doctor._id}>
                                <CopyOutlined
                                  onClick={() =>
                                    messageApi.success("ID copy successfully")
                                  }
                                />
                              </CopyToClipboard>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      )}
      <Modal
        title="Delete Group"
        open={modalVisible}
        onOk={handleDelete}
        onCancel={() => setModalVisible(false)}
      >
        <p>Are you sure you want to delete this group?</p>
      </Modal>
      <Modal
        title="Remove Doctor"
        open={removeModelVisible}
        onOk={handleRemove}
        onCancel={() => setRemoveModelVisible(false)}
      >
        <p>Are you sure you want to remove this doctor from the group?</p>
      </Modal>
      <Modal
        title="Add Doctor"
        open={addModelVisible}
        footer={null}
        onCancel={() => {
          form.resetFields();
          setAddModelVisible(false);
        }}
      >
        <Form onFinish={handlerAddDoctor}>
          <Form.Item
            name="doctorId"
            label="Doctor"
            rules={[
              {
                required: true,
                message: "Please select doctor",
              },
            ]}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
          >
            <Select
              style={{
                width: "100%",
              }}
              showSearch
              placeholder="Search by ID or Name"
              optionFilterProp="label"
              filterOption={false}
              onSearch={handleSearch}
              options={filterOptions}
            >
              {options.map((option) => {
                console.log(option);
                return (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item>
            {addModelError && <p style={{ color: "red" }}>{addModelError}</p>}
            <Row justify="center" align="middle">
              <Col>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
        <div>
          <h5>Note:*</h5>
          <p>
            In Options only show the doctors which is not the part of any Group.{" "}
          </p>
        </div>
      </Modal>
      <Modal
        title="Change total Group Payout"
        open={payoutModelVisible}
        footer={null}
        onCancel={() => {
          form.resetFields();
          setPayoutModelVisible(false);
        }}
      >
        <div>
          <p>
            Group Name:<span>{group?.name}</span>
          </p>
          <p>
            Current Total Payout:<span>{group?.totalGroupPayout}</span>
          </p>
        </div>
        <Form form={form} onFinish={handlerGroupTotalPayout}>
          <Form.Item
            name="totalPayout"
            label=" New Total Payout"
            rules={[
              {
                required: true,
                message: "Please Enter total payout",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item>
            <Row justify="center" align="middle">
              <Col>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Update Doctor Group Commission"
        open={commissionModelVisible}
        footer={null}
        onCancel={() => {
          form.resetFields();
          setCommissionModelVisible(false);
          setUpdateDoctorGroupCommModelError(null);
        }}
      >
        <div>
          {group &&
            group.doctors.map((doctor) => {
              if (doctor._id === doctorId) {
                return (
                  <div>
                    <p>
                      Name:<span>{doctor.name}</span>
                    </p>
                    <p>
                      Email:<span>{doctor.email}</span>
                    </p>
                    <p>
                      Current Commission:
                      <span>{doctor.groupDetails.groupComm}</span>
                    </p>
                  </div>
                );
              }
            })}
        </div>
        <Form form={form} onFinish={handlerDoctorGroupComm}>
          <Form.Item
            name="groupComm"
            label="New  Commission"
            rules={[
              {
                required: true,
                message: "Please Enter Commission",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item>
            <Row justify="center" align="middle">
              <Col>
                {updateDoctorGroupCommModelError && (
                  <p style={{ color: "red" }}>
                    {updateDoctorGroupCommModelError}
                  </p>
                )}
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Change Status"
        open={statusModelVisible}
        footer={null}
        onCancel={() => {
          setStatusModelVisible(false);
        }}
        width={380}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Form form={form} onFinish={handelStatusSubmit}>
            <Form.Item label="Status" name="status">
              <Select
                placeholder="Select Status"
                style={{
                  width: 200,
                }}
                options={groupStatusOptions}
              >
                {groupStatusOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Row justify="center" align="middle">
                <Col>
                  <Button type="primary" htmlType="submit">
                    Change Status
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </Fragment>
  );
};

export default GroupDetails;
