import axios from "axios";
import {
  AddServiceItemsUrl,
  ChangeAllocationUrl,
  DeductionUrl,
  GetAllDoctorsUrl,
  GetAllIndirectConsUrl,
  GetAllInvoices,
  GetAllServiceItemsUrl,
  GetAllocationChartUrl,
  GetAllGroupsUrl,
  GetOneGroupUrl,
  DeleteGroupUrl,
  AddDoctorInGroupUrl,
  DeleteDoctorFromGroupUrl,
  GetDoctorServicesUrl,
  GetFinalReportUrl,
  GetIndirectDataUrl,
  PushInvoiceUrl,
  UpdateDoctorCommissionUrl,
  EditDefaultCommUrl,
  CalculateDoctorPayoutUrl,
  CreateGroupUrl,
  ChangeInvoiceDoctorNameUrl,
  ChangeDoctorCommissionUrl,
  GetOneServiceItemUrl,
  UpdateServiceDefaultCommissionUrl,
  UpdateDoctorStatusUrl,
  AddDoctorUrl,
  UpdateGroupTotalPayoutUrl,
  UpdateDoctorGroupCommissionUrl,
  EditGroupNameUrl,
  EditDoctorUrl,
  GetOneDoctorUrl,
  EditServiceItemUrl,
  ChangeDoctorGroupStatusUrl,
  GetDoctorPayoutByDateUrl,
} from "./api";

export const GetAllInvoicesAction = async (params) => {
  try {
    let data = await axios.get(GetAllInvoices(), { params: { ...params } });
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const GetAllIndirectConsAction = async () => {
  try {
    let { data } = await axios.get(GetAllIndirectConsUrl());
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const GetDeductionAction = async () => {
  try {
    let { data } = await axios.get(DeductionUrl());
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const ChangeDeductionAction = async (params) => {
  try {
    let { data } = await axios.post(DeductionUrl(), params);
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const ChangeAllocationAction = async (params) => {
  try {
    let { data } = await axios.post(ChangeAllocationUrl(), params);
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const GetAllocationChartAction = async () => {
  try {
    let { data } = await axios.get(GetAllocationChartUrl());
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const GetFinalReportAction = async () => {
  try {
    let { data } = await axios.get(GetFinalReportUrl());
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const GetAllIndirectStoreWiseAction = async () => {
  try {
    let { data } = await axios.get(GetIndirectDataUrl());
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const PushInvoiceAction = async (params) => {
  try {
    let data = await axios.post(PushInvoiceUrl(), params);
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const GetAllDoctorsAction = async (params) => {
  try {
    let data = await axios.get(GetAllDoctorsUrl(), { params: { ...params } });
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const GetDoctorPayoutByDateAction = async (period) => {
  try {
    let data = await axios.get(GetDoctorPayoutByDateUrl(period));
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const UpdateDoctorCommission = async (params) => {
  try {
    let data = await axios.patch(UpdateDoctorCommissionUrl(), params);
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const EditServiceItemAction = async (serviceId, params) => {
  try {
    let data = await axios.patch(EditServiceItemUrl(serviceId), params);
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const GetAllServiceItemsAction = async (params) => {
  try {
    let data = await axios.get(GetAllServiceItemsUrl(), {
      params: { ...params },
    });
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const GetAllGroupsAction = async () => {
  try {
    let data = await axios.get(GetAllGroupsUrl());
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const GetOneGroupsAction = async (params) => {
  try {
    let data = await axios.get(GetOneGroupUrl(params));
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const DeleteGroupAction = async (groupId) => {
  console.log(groupId);
  try {
    let data = await axios.delete(DeleteGroupUrl(), {
      data: {
        id: groupId,
      },
    });
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const DeleteDoctorFromGroupAction = async (doctorId) => {
  try {
    let data = await axios.delete(DeleteDoctorFromGroupUrl(doctorId));
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const AddDoctorInGroupAction = async (groupId, doctorId) => {
  let res = await axios.post(AddDoctorInGroupUrl(groupId, doctorId));
  return res;
};
export const AddServiceItemAction = async (params) => {
  try {
    let data = await axios.post(AddServiceItemsUrl(), params);
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const EditDefaultComm = async (params) => {
  try {
    console.log(params);
    let data = await axios.patch(EditDefaultCommUrl(), params);
    console.log(data);
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const GetDoctorServicesAction = async (id, params) => {
  try {
    let data = await axios.get(GetDoctorServicesUrl(id), {
      params: { ...params },
    });
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const CalculatDoctorPayoutAction = async () => {
  try {
    let data = await axios.get(CalculateDoctorPayoutUrl());
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const CreateGroupAction = async (params) => {
  try {
    let data = await axios.post(CreateGroupUrl(), {
      ...params,
    });
    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const ChangeInvoiceDoctorNameAction = async (params) => {
  try {
    let data = await axios.post(ChangeInvoiceDoctorNameUrl(), {
      ...params,
    });

    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const ChangeDoctorCommissionAction = async (params) => {
  try {
    let data = await axios.patch(ChangeDoctorCommissionUrl(), {
      ...params,
    });

    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const GetOneServiceItemAction = async (serviceId) => {
  try {
    let data = await axios.get(GetOneServiceItemUrl(serviceId));

    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const UpdateServiceDefaultCommissionAction = async (params) => {
  try {
    let data = await axios.patch(UpdateServiceDefaultCommissionUrl(), {
      ...params,
    });

    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const UpdateDoctorStatusAction = async (params) => {
  try {
    let data = await axios.patch(UpdateDoctorStatusUrl(), {
      ...params,
    });

    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const AddDoctorAction = async (params) => {
  try {
    let data = await axios.post(AddDoctorUrl(), {
      ...params,
    });

    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const UpdateGroupTotalPayoutAction = async (params) => {
  try {
    let data = await axios.patch(UpdateGroupTotalPayoutUrl(), {
      ...params,
    });

    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const UpdateDoctorGroupCommissionAction = async (params) => {
  const res = await axios.patch(UpdateDoctorGroupCommissionUrl(), {
    ...params,
  });

  return res;
};
export const ChangeDoctorGroupStatusAction = async (params) => {
  try {
    let data = await axios.patch(ChangeDoctorGroupStatusUrl(), {
      ...params,
    });

    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const EditDoctorAction = async (doctorId, params) => {
  try {
    let data = await axios.patch(EditDoctorUrl(doctorId), {
      ...params,
    });

    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const GetOneDoctorAction = async (doctorId) => {
  try {
    let data = await axios.get(GetOneDoctorUrl(doctorId));

    return data;
  } catch (error) {
    console.log("error is ", error);
  }
};
export const EditGroupNameAction = async (params) => {
  const response = await axios.patch(EditGroupNameUrl(), {
    ...params,
  });

  return response;
};
