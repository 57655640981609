export const Data = [
  {
    time: 2010,
    value: 230000,
    type: "Total Amount",
  },
  {
    time: 2011,
    value: 300000,
    type: "Total Amount",
  },
  {
    time: 2012,
    value: 500000,
    type: "Total Amount",
  },
  {
    time: 2013,
    value: 1500000,
    type: "Total Amount",
  },
  {
    time: 2014,
    value: 2200000,
    type: "Total Amount",
  },
  {
    time: 2015,
    value: 1400000,
    type: "Total Amount",
  },
  {
    time: 2016,
    value: 1000000,
    type: "Total Amount",
  },
  {
    time: 2017,
    value: 1200000,
    type: "Total Amount",
  },
  {
    time: 2018,
    value: 500000,
    type: "Total Amount",
  },
  {
    time: 2019,
    value: 2000000,
    type: "Total Amount",
  },
  {
    time: 2020,
    value: 2200000,
    type: "Total Amount",
  },
  {
    time: 2021,
    value: 2500000,
    type: "Total Amount",
  },
  {
    time: 2022,
    value: 1500000,
    type: "Total Amount",
  },

  {
    time: 2010,
    value: 23000,
    type: "Net Amount",
  },
  {
    time: 2011,
    value: 25000,
    type: "Net Amount",
  },
  {
    time: 2012,
    value: 15000,
    type: "Net Amount",
  },
  {
    time: 2013,
    value: 200000,
    type: "Net Amount",
  },
  {
    time: 2014,
    value: 70000,
    type: "Net Amount",
  },
  {
    time: 2015,
    value: 100000,
    type: "Net Amount",
  },
  {
    time: 2016,
    value: 15000,
    type: "Net Amount",
  },
  {
    time: 2017,
    value: 15000,
    type: "Net Amount",
  },
  {
    time: 2018,
    value: 15000,
    type: "Net Amount",
  },
  {
    time: 2019,
    value: 150000,
    type: "Net Amount",
  },
  {
    time: 2020,
    value: 80000,
    type: "Net Amount",
  },
  {
    time: 2021,
    value: 10000,
    type: "Net Amount",
  },
  {
    time: 2022,
    value: 90000,
    type: "Net Amount",
  },
];
