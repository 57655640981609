export const NeuroData = [
  {
    key: "1",
    patientName: "Suraj Kumar",
    contact: {
      email: "mail@mail.com",
      mobile: 12341234,
      state: "Uttar Pradesh",
    },

    address: "New York No. 1 Lake Park",
    status: "serious",
    latestReport: "Blood Pressure",
    guardianName: "Piyush Kumar",
    department: ["Internal Medicine", "Nephrology"],
    type: "A",
  },
  {
    key: "2",
    patientName: "Dileep Raj",
    contact: {
      email: "mail@mail.com",
      mobile: 12341234,
      state: "Uttar Pradesh",
    },
    address: "London No. 1 Lake Park",
    status: "normal",
    guardianName: "Samrat Singh",
    latestReport: "Blood Pressure",
    department: ["Internal Medicine", "Nephrology"],
    type: "B",
  },
  {
    key: "3",
    patientName: "Ankita Sinha",
    contact: {
      email: "mail@mail.com",
      mobile: 12341234,
      state: "Uttar Pradesh",
    },

    address: "Sidney No. 1 Lake Park",
    status: "uncertain",
    department: ["Nephrology"],
    guardianName: "Prashant Verma",
    latestReport: "Blood Pressure",
    type: "B",
  },
];
export const internalMedData = [
  {
    key: "1",
    patientName: "Suraj Kumar",
    contact: {
      email: "mail@mail.com",
      mobile: 12341234,
      state: "Uttar Pradesh",
    },

    address: "New York No. 1 Lake Park",
    status: "serious",
    latestReport: "Blood Pressure",
    guardianName: "Piyush Kumar",
    department: ["Internal Medicine", "Nephrology"],
    type: "A",
  },
  {
    key: "2",
    patientName: "Dileep Raj",
    contact: {
      email: "mail@mail.com",
      mobile: 12341234,
      state: "Uttar Pradesh",
    },
    address: "London No. 1 Lake Park",
    status: "normal",
    guardianName: "Samrat Singh",
    latestReport: "Blood Pressure",
    department: ["Internal Medicine"],
    type: "B",
  },
  {
    key: "3",
    patientName: "Pradeep Singh",
    contact: {
      email: "mail@mail.com",
      mobile: 12341234,
      state: "Uttar Pradesh",
    },

    address: "Sidney No. 1 Lake Park",
    status: "uncertain",
    department: ["Internal Medicine", "Orthopaedics"],
    guardianName: "Prashant Verma",
    latestReport: "Blood Pressure",
    type: "B",
  },
];
export const orthoData = [
  {
    key: "1",
    patientName: "Saurabh Kumar",
    contact: {
      email: "mail@mail.com",
      mobile: 12341234,
      state: "Uttar Pradesh",
    },

    address: "New York No. 1 Lake Park",
    status: "serious",
    latestReport: "Blood Pressure",
    guardianName: "Piyush Kumar",
    department: ["Orthopaedics"],
    type: "A",
  },
  {
    key: "2",
    patientName: "Pradeep Singh",
    contact: {
      email: "mail@mail.com",
      mobile: 12341234,
      state: "Uttar Pradesh",
    },
    address: "London No. 1 Lake Park",
    status: "normal",
    guardianName: "Samrat Singh",
    latestReport: "Blood Pressure",
    department: ["Internal Medicine", "Orthopaedics"],
    type: "B",
  },
  {
    key: "3",
    patientName: "Sumit Verma",
    contact: {
      email: "mail@mail.com",
      mobile: 12341234,
      state: "Uttar Pradesh",
    },

    address: "Sidney No. 1 Lake Park",
    status: "uncertain",
    department: ["Orthopaedics"],
    guardianName: "Prashant Verma",
    latestReport: "Blood Pressure",
    type: "B",
  },
];
