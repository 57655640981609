import {
  Breadcrumb,
  Button,
  Card,
  Tag,
  theme,
  Tooltip,
  Drawer,
  Timeline,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/sidebar";
import { TableComp } from "sz-react-utils4";
import moment from "moment";
import {
  BookOutlined,
  DollarOutlined,
  IdcardOutlined,
  MessageOutlined,
  PlusOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { NeuroData, orthoData } from "../constraints/deparatmentData";
import "../styles/globalStyle.css";
import { AccountData } from "../constraints/accoutsData";
const Accounts = () => {
  const [count, setCount] = useState();
  const [drawerVis, setDrawerVis] = useState(false);
  const tableRef = useRef();
  const {
    token: { colorBgContainer, colorPrimaryBg, colorText },
  } = theme.useToken();
  const onClose = () => {
    setDrawerVis(false);
  };
  const myColumns = [
    {
      title: "Speciality",
      dataIndex: "speciality",
      key: "speciality",
      width: 125,
      //   searchTextName: "Patient name",
      fixed: "left",
      sorter: true,
    },
    {
      sorter: true,
      title: "Department",
      dataIndex: "department",
      width: 180,
      key: "department",
      render: (val) => {
        return val.map((item, key) => {
          if (item && item.length > 0) {
            return (
              <Tag key={key} className="mt-2">
                {item}
              </Tag>
            );
          }
        });
      },
    },
    {
      sorter: true,
      title: "Revenue",
      width: 140,
      dataIndex: "revenue",
      key: "revenue",
      // searchTextName: "Guardian Name",
    },

    {
      title: "M-IndirectCost",
      dataIndex: "mdirect",
      sorter: true,
      width: 140,
      key: "mdirect",
    },
    {
      title: "N-Indirectcost",
      sorter: true,
      width: 140,
      dataIndex: "ndirect",
      key: "ndirect",
    },
    {
      title: "Direct",
      dataIndex: "direct",
      sorter: true,
      width: 100,
      key: "direct",
    },
    {
      title: "Deduction",
      key: "deduction",
      width: 150,
      sorter: true,
    },
  ];
  const [selectedRowKeys, setSeletedRowKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSeletedRowKeys(selectedRowKeys);
    },
  };
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      //   params.status = "Open";
      //   if (!params.sortField) {
      //     params.sortField = "createdAt";
      //     params.sortOrder = "descend";
      //   }
      //   let data = await getAllComplaintsAction({
      //     ...params,
      //     regExFilters: [
      //       "complaintRaisedByName",
      //       "complaintDetails",
      //       "productName",
      //     ],
      //   });
      setCount(NeuroData.total);
      resolve(NeuroData);
    });
  };

  return (
    <Content
      style={{
        margin: "0 16px",
      }}
    >
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>Listings</Breadcrumb.Item>
      </Breadcrumb>

      <Card bordered={true}>
        <div className="ml-auto flex-row flex items-center justify-end">
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: colorPrimaryBg,
              marginRight: 10,
            }}
          >
            <DollarOutlined
              style={{ color: "#fff", marginRight: 5, fontSize: 18 }}
            />
            <text style={{ color: "#fff", fontWeight: "500" }}>REVENUE</text>
          </Button>
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: colorPrimaryBg,
            }}
          >
            <PlusOutlined
              style={{ color: "#fff", fontSize: 18, marginRight: 5 }}
            />
            <text style={{ color: "#fff", fontWeight: "500" }}>ADD</text>
          </Button>
        </div>

        <TableComp
          ref={tableRef}
          columns={myColumns}
          extraProps={{ scroll: { x: 300 } }}
          // apiRequest={apiRequest}
          dataSource={AccountData}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ["10", "25", "50", "100"],
            showSizeChanger: true,
          }}
        />
      </Card>
      <Drawer
        title="All Reports"
        width={500}
        placement="right"
        onClose={onClose}
        visible={drawerVis}
      >
        <Timeline mode={"left"} style={{ padding: "20px" }}>
          <Timeline.Item>
            <a onClick={() => {}}>
              Blood Pressure by <b>{"Dr. Goel"}</b> on{" "}
              <b>{moment(new Date()).format("lll")}</b>
            </a>
          </Timeline.Item>
          <Timeline.Item>
            <a onClick={() => {}}>
              Diabetes by <b>{"Dr. Goel"}</b> on{" "}
              <b>{moment(new Date()).format("lll")}</b>
            </a>
          </Timeline.Item>
        </Timeline>
      </Drawer>
    </Content>
  );
};

export default Accounts;
