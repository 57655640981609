import {
  Breadcrumb,
  Button,
  Card,
  Tag,
  theme,
  Tooltip,
  Drawer,
  Timeline,
  Table,
  Modal,
  Upload,
  Select,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/sidebar";
import { TableComp } from "sz-react-utils4";
import moment from "moment";
import {
  BookOutlined,
  DollarOutlined,
  IdcardOutlined,
  MessageOutlined,
  PlusOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { NeuroData } from "../constraints/deparatmentData";
import "../styles/globalStyle.css";
import {
  ChangeAllocationAction,
  GetAllIndirectStoreWiseAction,
  GetAllInvoicesAction,
  PushInvoiceAction,
} from "../utils/action";
import { UploadOutlined } from "@ant-design/icons";
import { InvoiceUploadUrl } from "../utils/api";

const Allocation = () => {
  const [count, setCount] = useState();
  const [invoicefile, setInvoiceFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [okLoad, setOkLoad] = useState(false);
  const [data, setData] = useState([]);
  const [drawerVis, setDrawerVis] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tableRef = useRef();
  const handleChange = async (label, val) => {
    let obj = {
      storeName: val.storeName,
      allocation: label,
    };
    setLoading(true);

    let data = await ChangeAllocationAction(obj);
    setLoading(false);

    if (data && data.message) {
      // getIndirectData();
      tableRef.current.reload();
    }
  };
  const {
    token: { colorBgContainer, colorPrimaryBg, colorText },
  } = theme.useToken();
  const onClose = () => {
    setDrawerVis(false);
  };
  const props = {
    name: "invoice",
    action: InvoiceUploadUrl(),
    headers: {
      authorization: "authorization-text",
    },
    maxCount: 1,
  };
  const getIndirectData = async () => {
    setLoading(true);
    let data = await GetAllIndirectStoreWiseAction();
    setLoading(false);
    if (data) {
      setData(data.consumption);
      setCount(data.total);
      return null;
    }
  };
  useEffect(() => {
    getIndirectData();
  }, []);
  const myColumns = [
    {
      title: "Sub-Store Name",
      dataIndex: "storeName",
      key: "storeName",
      width: 150,
      fixed: "left",
    },
    {
      title: "Medical",
      dataIndex: "medical",
      key: "medical",
      width: 80,
    },
    {
      title: "Non Medical",
      dataIndex: "nonMedical",
      key: "nonMedical",
      width: 80,
    },
    {
      title: "Grand Total",
      dataIndex: "grandTotal",
      key: "grandTotal",
      width: 80,
      render: (ii, val) => {
        return <div>{val.nonMedical + val.medical}</div>;
      },
    },
    {
      title: "Alloction Type",
      dataIndex: "allocationType",
      key: "allocationType",
      width: 80,
      render: (item, val) => {
        return (
          <Select
            defaultValue={item}
            style={{
              width: 120,
            }}
            onChange={(label) => handleChange(label, val)}
            options={[
              {
                value: "Direct",
                label: "Direct",
              },
              {
                value: "Lab Test",
                label: "Lab Test",
              },
              {
                value: "Total Revenue",
                label: "Total Revenue",
              },
            ]}
          />
        );
      },
    },
    {
      key: "actions",
      title: "Actions",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip color={colorPrimaryBg} title="Add Query">
              <Button
                shape="circle"
                style={{ marginRight: 6, marginBottom: 6 }}
                size="small"
                icon={<MessageOutlined />}
              />
            </Tooltip>
            <Tooltip color={colorPrimaryBg} title="Change Status">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<BookOutlined />}
              />
            </Tooltip>
            <Tooltip color={colorPrimaryBg} title="View Report">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<ProfileOutlined />}
                onClick={() => {
                  setDrawerVis(true);
                }}
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ];
  const [selectedRowKeys, setSeletedRowKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSeletedRowKeys(selectedRowKeys);
    },
  };
  const apiRequest = () => {
    return new Promise(async (resolve) => {
      let data = await GetAllInvoicesAction();
      setData(data.data.invoice);
      setCount(data.data.invoice.length);
      console.log(data.data);
      resolve(data.data);
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setOkLoad(true);
    let data = await PushInvoiceAction();
    setOkLoad(false);

    if (data.data.success) {
      setIsModalOpen(false);
      setInvoiceFile([]);
      getIndirectData();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    console.log(tableRef.current);
    // tableRef.current.reload();
  };
  return (
    <Content
      style={{
        margin: "0 16px",
      }}
    >
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>Invoices</Breadcrumb.Item>
      </Breadcrumb>

      <Card bordered={true}>
        <TableComp
          ref={tableRef}
          columns={myColumns}
          extraProps={{ scroll: { x: 300 } }}
          dataSource={data}
          loading={loading}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ["10", "25", "50", "100"],
            showSizeChanger: true,
          }}
        />
        {/* <Table
          ref={tableRef}
          columns={myColumns}
          scroll={{ x: 300 }}
          dataSource={data}
          loading={loading}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ["10", "25", "50", "100"],
            showSizeChanger: true,
          }}
        /> */}
      </Card>
      <Drawer
        title="All Reports"
        width={500}
        placement="right"
        onClose={onClose}
        open={drawerVis}
      >
        <Timeline mode={"left"} style={{ padding: "20px" }}>
          <Timeline.Item>
            <a onClick={() => {}}>
              Blood Pressure by <b>{"Dr. Goel"}</b> on{" "}
              <b>{moment(new Date()).format("lll")}</b>
            </a>
          </Timeline.Item>
          <Timeline.Item>
            <a onClick={() => {}}>
              Diabetes by <b>{"Dr. Goel"}</b> on{" "}
              <b>{moment(new Date()).format("lll")}</b>
            </a>
          </Timeline.Item>
        </Timeline>
      </Drawer>
      <Modal
        title="Invoice Upload"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={okLoad}
        width={380}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Upload {...props} multiple={false}>
            <Button icon={<UploadOutlined />}>Upload Invoice</Button>
          </Upload>
        </div>
      </Modal>
    </Content>
  );
};

export default Allocation;
