import {
  Button,
  Card,
  theme,
  Tooltip,
  Drawer,
  Form,
  Input,
  Dropdown,
  Breadcrumb,
  Menu,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { TableComp } from "sz-react-utils4";
import moment from "moment";
import { ProfileOutlined, CaretDownOutlined } from "@ant-design/icons";

import "../styles/globalStyle.css";
import {
  EditServiceItemAction,
  GetOneServiceItemAction,
} from "../utils/action";

const EditServiceItem = () => {
  const [service, setService] = useState();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { serviceId } = useParams();
  // get the serviceItem data from the given id from the server
  const getServiceItemData = async () => {
    const { data } = await GetOneServiceItemAction(serviceId);
    console.log(data.data.service);
    setService(data.data.service);
    form.setFieldsValue({
      serviceName: data.data.service.serviceName,
      serviceItemCode: data.data.service.serviceItemCode,
      serviceType: data.data.service.serviceType,
      serviceGroupName: data.data.service.serviceGroupName,
      defaultComm: data.data.service.defaultComm,
    });
  };

  useEffect(() => {
    getServiceItemData();
  }, []);

  const handleFormSubmit = (value) => {
    console.log(value);
    const sendServiceData = async () => {
      const data = await EditServiceItemAction(serviceId, value);
      console.log(data);
      if (data.data.success) {
        navigate("/serviceItems");
      }
    };
    sendServiceData();
  };
  return (
    <Fragment>
      <Breadcrumb style={{ margin: "20px 0" }}>
        <Breadcrumb.Item key={"/serviceItems/edit"}>
          {"ServiceItems/Edit"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          marginTop: "40px",
        }}>
        <Form form={form} onFinish={handleFormSubmit}>
          <Form.Item
            label="Service Name"
            name="serviceName"
            rules={[
              { required: true, message: "Please enter the service name" },
            ]}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}>
            <Input placeholder="Enter ServiceName" />
          </Form.Item>
          <Form.Item
            label="ServiceItemCode"
            name="serviceItemCode"
            rules={[
              { required: true, message: "Please enter the service item code" },
            ]}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}>
            <Input placeholder="Enter ServiceItemCode " disabled />
          </Form.Item>
          <Form.Item
            label="service Type"
            name="serviceType"
            rules={[
              { required: true, message: "Please enter the serviceType" },
            ]}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}>
            <Input placeholder="Enter serviceType " />
          </Form.Item>
          <Form.Item
            label="serviceGroupName"
            name="serviceGroupName"
            rules={[
              { required: true, message: "Please enter the serviceGroupName" },
            ]}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}>
            <Input placeholder="Enter serviceGroupName " />
          </Form.Item>
          <Form.Item
            label="Commission"
            name="defaultComm"
            rules={[{ required: true, message: "Please enter Commission" }]}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}>
            <Input placeholder="Enter Commission " />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 11, span: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Fragment>
  );
};

export default EditServiceItem;
