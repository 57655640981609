import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { Card, Col, Row, theme } from "antd";
import React from "react";

const DashboardCards = () => {
  const {
    token: { colorText },
  } = theme.useToken();
  return (
    <Row className="mb-3 mt-5" gutter={16}>
      <Col span={6}>
        <Card hoverable className="h-40" bordered={true}>
          <div className=" flex flex-col items-center justify-center">
            <div className="">
              <CaretUpOutlined style={{ color: "green", fontSize: 25 }} />
              <text className="text-2xl text-green-700 font-semibold">
                100%
              </text>
            </div>
            <text className="text-center text-lg font-semibold">Contacts</text>

            <div className="flex flex-row justify-center mt-2 relative right-2">
              <div
                className="flex flex-col pr-2 "
                style={{
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 1.2,
                  borderColor: colorText,
                  borderStyle: "solid",
                }}
              >
                <text className="text-right font-semibold text-md">1400</text>
                <text className="text-right font-medium text-md">
                  Current Month
                </text>
              </div>
              <div className="flex flex-col ml-2">
                <text className="text-left font-semibold text-md">700</text>
                <text className="text-left font-medium text-md">
                  Last Month
                </text>
              </div>
            </div>
          </div>
        </Card>
      </Col>
      <Col span={6}>
        <Card hoverable className="h-40" bordered={true}>
          <div className=" flex flex-col items-center justify-center">
            <div className="">
              <CaretDownOutlined style={{ color: "red", fontSize: 25 }} />
              <text className="text-2xl text-red-700 font-semibold">
                -28.05%
              </text>
            </div>
            <text className="text-center text-lg font-semibold">Revenue</text>

            <div className="flex flex-row justify-center mt-2 relative right-2">
              <div
                className="flex flex-col border-gray-700 pr-2"
                style={{
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 1.2,
                  borderColor: colorText,
                  borderStyle: "solid",
                }}
              >
                <text className="text-right font-semibold text-md">₹2.2M</text>
                <text className="text-right font-medium text-md">
                  Current Month
                </text>
              </div>
              <div className="flex flex-col ml-2">
                <text className="text-left font-semibold text-md">₹2.7M</text>
                <text className="text-left font-medium text-md">
                  Last Month
                </text>
              </div>
            </div>
          </div>
        </Card>
      </Col>
      <Col span={6}>
        <Card hoverable className="h-40" bordered={true}>
          <div className=" flex flex-col items-center justify-center">
            <div className="">
              <CaretDownOutlined style={{ color: "red", fontSize: 25 }} />
              <text className="text-2xl text-red-700 font-semibold">-25%</text>
            </div>
            <text className="text-center text-lg font-semibold">Occupancy</text>

            <div className="flex flex-row justify-center mt-2 relative right-2">
              <div
                className="flex flex-col border-gray-700 pr-2"
                style={{
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 1.2,
                  borderColor: colorText,
                  borderStyle: "solid",
                }}
              >
                <text className="text-right font-semibold text-md">2200</text>
                <text className="text-right font-medium text-md">
                  Current Month
                </text>
              </div>
              <div className="flex flex-col ml-2">
                <text className="text-left font-semibold text-md">2750</text>
                <text className="text-left font-medium text-md">
                  Last Month
                </text>
              </div>
            </div>
          </div>
        </Card>
      </Col>
      <Col span={6}>
        <Card hoverable className="h-40" bordered={true}>
          <div className=" flex flex-col items-center justify-center">
            <div className="">
              <CaretUpOutlined style={{ color: "green", fontSize: 25 }} />
              <text className="text-2xl text-green-700 font-semibold">
                40.85%
              </text>
            </div>
            <text className="text-center text-lg font-semibold">
              Subscription
            </text>

            <div className="flex flex-row justify-center mt-2 relative right-2">
              <div
                className="flex flex-col border-gray-700 pr-2"
                style={{
                  borderTopWidth: 0,
                  borderBottomWidth: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 1.2,
                  borderColor: colorText,
                  borderStyle: "solid",
                }}
              >
                <text className="text-right font-semibold text-md">40k</text>
                <text className="text-right font-medium text-md">
                  Current Month
                </text>
              </div>
              <div className="flex flex-col ml-2">
                <text className="text-left font-semibold text-md">25k</text>
                <text className="text-left font-medium text-md">
                  Last Month
                </text>
              </div>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default DashboardCards;
