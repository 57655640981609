import { Button, Form, Input, Select, Breadcrumb } from "antd";

import { useNavigate } from "react-router-dom";

import "../styles/globalStyle.css";
import { AddDoctorAction } from "../utils/action";
import { Fragment } from "react";

const { Option } = Select;

const options = [
  { label: "NEPHROLOGY", value: "NEPHROLOGY" },
  { label: "EHC", value: "EHC" },
  { label: "PLASTIC SURGERY", value: "PLASTIC SURGERY" },
];
const AddDoctor = () => {
  const navigate = useNavigate();

  const handleFormSubmit = (value) => {
    console.log(value);
    const sendDoctorData = async () => {
      const data = await AddDoctorAction(value);
      console.log(data);
      if (data.data.success) {
        navigate("/doctors");
      }
    };
    sendDoctorData();
  };
  return (
    <Fragment>
      <Breadcrumb style={{ margin: "20px 0" }}>
        <Breadcrumb.Item key={"/doctors/new"}>{"Doctors/new"}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          marginTop: "40px",
        }}>
        <Form onFinish={handleFormSubmit}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please enter the name" }]}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}>
            <Input placeholder="Enter Name" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please enter the Email" }]}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}>
            <Input placeholder="Enter Email " />
          </Form.Item>
          <Form.Item
            label="Department"
            name="department"
            rules={[{ required: true, message: "Please enter the department" }]}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}>
            <Select
              placeholder="Select department"
              style={{
                width: 200,
              }}
              options={options}>
              {options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 11, span: 8 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Fragment>
  );
};

export default AddDoctor;
