import {
  Breadcrumb,
  Button,
  Card,
  Tag,
  theme,
  Tooltip,
  Drawer,
  Timeline,
  Table,
  Modal,
  Upload,
  InputNumber,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/sidebar";
import { TableComp } from "sz-react-utils4";
import moment from "moment";
import {
  BookOutlined,
  DollarOutlined,
  IdcardOutlined,
  LeftCircleFilled,
  MessageOutlined,
  PlusOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { NeuroData } from "../constraints/deparatmentData";
import "../styles/globalStyle.css";
import {
  ChangeDeductionAction,
  GetAllInvoicesAction,
  GetDeductionAction,
  GetFinalReportAction,
  PushInvoiceAction,
} from "../utils/action";
import { UploadOutlined } from "@ant-design/icons";
import { InvoiceUploadUrl } from "../utils/api";
import PieChart from "../components/pieChart";
import BasicBar from "../components/basicBar";

const FinalReport = () => {
  const [count, setCount] = useState();
  const [pieData, setPieData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [invoicefile, setInvoiceFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deduction, setDeduction] = useState(0);
  const [inputNum, setInputNum] = useState(0);
  const [okLoad, setOkLoad] = useState(false);
  const [data, setData] = useState();
  const [drawerVis, setDrawerVis] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tableRef = useRef();
  const {
    token: { colorBgContainer, colorPrimaryBg, colorText },
  } = theme.useToken();
  const onClose = () => {
    setDrawerVis(false);
  };
  const props = {
    name: "invoice",
    action: InvoiceUploadUrl(),
    headers: {
      authorization: "authorization-text",
    },
    maxCount: 1,
  };
  const getFinalReportData = async () => {
    setLoading(true);
    let data2 = await GetDeductionAction();
    let data = await GetFinalReportAction();
    setLoading(false);

    if (data && data2) {
      setDeduction(data2.deduction[0].deduction);
      setInputNum(data2.deduction[0].deduction);
      console.log(data2.deduction[0].deduction, " this is deduction");
      setData(data.finalReport);
      setCount(data.finalReport.length);
      return null;
    }
  };
  useEffect(() => {
    getFinalReportData();
  }, []);
  useEffect(() => {
    let arr = [];
    let arr2 = [];
    if (data && data.length > 0) {
      data.map((item, kk) => {
        let obj = {
          type: item.departmentName,
          value: item.grossContributionPercent,
        };
        let obj2 = {
          department: item.departmentName,
          value: item.grossContribution,
        };
        arr.push(obj);
        arr2.push(obj2);
      });
      console.log(arr2, " th is si array  of gross");
      setPieData(arr);
      setBarData(arr2);
    }
  }, [data]);
  const myColumns = [
    {
      title: "Department Name",
      dataIndex: "departmentName",
      key: "departmentName",
      width: 170,
      fixed: "left",
    },
    {
      title: "Total Revenue",
      key: "deptWiseTotalRevenue",
      dataIndex: "deptWiseTotalRevenue",
      width: 120,
      render: (item) => {
        return <div>{Math.round(item)}</div>;
      },
    },
    {
      title: "Direct Cost",
      key: "deptWiseDirectCost",
      dataIndex: "deptWiseDirectCost",
      width: 120,
      render: (item) => {
        return <div>{Math.round(item)}</div>;
      },
    },
    {
      title: "Indirect Medical Cost",
      key: "deptWiseIndirectMedCost",
      dataIndex: "deptWiseIndirectMedCost",
      width: 120,
      render: (item) => {
        return <div>{Math.round(item)}</div>;
      },
    },
    {
      title: "Indirect Non-Medical Cost",
      key: "deptWiseIndirectNonMedCost",
      dataIndex: "deptWiseIndirectNonMedCost",
      width: 120,
      render: (item) => {
        return <div>{Math.round(item)}</div>;
      },
    },
    {
      title: `Deduction (${deduction}%)`,
      key: "deduction",
      dataIndex: "deduction",
      width: 140,
      render: (item) => {
        return <div>{Math.round(item)}</div>;
      },
    },
    {
      title: "Total Cost & Deduction",
      key: "totalCostWithDeduction",
      dataIndex: "totalCostWithDeduction",
      width: 120,
      render: (item) => {
        return <div>{Math.round(item)}</div>;
      },
    },
    {
      title: "Gross Contribution",
      key: "grossContribution",
      dataIndex: "grossContribution",
      width: 120,
      render: (item) => {
        return <div>{Math.round(item)}</div>;
      },
    },
    {
      title: "Gross Contribution %",
      key: "grossContributionPercent",
      dataIndex: "grossContributionPercent",
      width: 150,
      render: (item) => {
        return <div>{Math.round(item)}%</div>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip color={colorPrimaryBg} title="Add Query">
              <Button
                shape="circle"
                style={{ marginRight: 6, marginBottom: 6 }}
                size="small"
                icon={<MessageOutlined />}
              />
            </Tooltip>
            <Tooltip color={colorPrimaryBg} title="Change Status">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<BookOutlined />}
              />
            </Tooltip>
            <Tooltip color={colorPrimaryBg} title="View Report">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<ProfileOutlined />}
                onClick={() => {
                  setDrawerVis(true);
                }}
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ];
  const [selectedRowKeys, setSeletedRowKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSeletedRowKeys(selectedRowKeys);
    },
  };
  const apiRequest = () => {
    return new Promise(async (resolve) => {
      let data = await GetAllInvoicesAction();
      setData(data.data.invoice);
      setCount(data.data.invoice.length);
      console.log(data.data);
      resolve(data.data);
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    setOkLoad(true);
    let obj = {
      deduction: inputNum,
    };
    let data = await ChangeDeductionAction(obj);
    setOkLoad(false);

    if (data.success) {
      setIsModalOpen(false);
      setInvoiceFile([]);
      getFinalReportData();
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    console.log(tableRef.current);
    // tableRef.current.reload();
  };
  return (
    <Content
      style={{
        margin: "0 16px",
      }}
    >
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>Report</Breadcrumb.Item>
      </Breadcrumb>

      <Card bordered={true}>
        <div className="ml-auto flex-row flex items-center justify-end mb-3">
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: colorPrimaryBg,
            }}
            onClick={showModal}
          >
            <BookOutlined style={{ color: "#fff", fontSize: 15 }} />
            {/* <text style={{ color: "#fff", fontWeight: "500" }}>
              Change Deduction
            </text> */}
          </Button>
        </div>

        <TableComp
          ref={tableRef}
          columns={myColumns}
          extraProps={{ scroll: { x: 300 } }}
          dataSource={data}
          loading={loading}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ["10", "25", "50", "100"],
            showSizeChanger: true,
          }}
        />
        {/* <Table
          ref={tableRef}
          columns={myColumns}
          scroll={{ x: 300 }}
          dataSource={data}
          loading={loading}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ["10", "25", "50", "100"],
            showSizeChanger: true,
          }}
        /> */}
      </Card>
      <div className="mt-4 flex flex-row items-center justify-start mb-3">
        <Card className="mr-4 flex-1">
          <PieChart data={pieData} />
        </Card>
        <Card className="flex-1">
          <BasicBar data={barData} />
        </Card>
      </div>
      <Drawer
        title="All Reports"
        width={500}
        placement="right"
        onClose={onClose}
        open={drawerVis}
      >
        <Timeline mode={"left"} style={{ padding: "20px" }}>
          <Timeline.Item>
            <a onClick={() => {}}>
              Blood Pressure by <b>{"Dr. Goel"}</b> on{" "}
              <b>{moment(new Date()).format("lll")}</b>
            </a>
          </Timeline.Item>
          <Timeline.Item>
            <a onClick={() => {}}>
              Diabetes by <b>{"Dr. Goel"}</b> on{" "}
              <b>{moment(new Date()).format("lll")}</b>
            </a>
          </Timeline.Item>
        </Timeline>
      </Drawer>
      <Modal
        title="Change Deduction Percentage"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={okLoad}
        width={380}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <InputNumber
            min={0}
            max={100}
            defaultValue={inputNum}
            onChange={(val) => setInputNum(val)}
          />
        </div>
      </Modal>
    </Content>
  );
};

export default FinalReport;
