import React from "react";
import classes from "../styles/groupDetails.module.css";

const GroupStatus = (props) => {
  let classApply;

  if (props.status === "pending") {
    classApply = `${classes.groupStatus} ${classes.pending}`;
  } else if (props.status === "approved") {
    classApply = `${classes.groupStatus} ${classes.approved}`;
  } else if (props.status === "rejected") {
    classApply = `${classes.groupStatus} ${classes.rejected}`;
  } else {
    classApply = `${classes.groupStatus} ${classes.flag}`;
  }

  return (
    <>
      <span className={classApply}>{props.status.toUpperCase()}</span>
    </>
  );
};

export default GroupStatus;
