import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  FlagOutlined,
} from "@ant-design/icons";

const ShowStatus = (props) => {
  return (
    <div>
      {props.status === "pending" && (
        <p
          style={{
            color: "orange",
          }}>
          {props.showIcon && <LoadingOutlined />}

          <span
            style={{
              marginLeft: "10px",
            }}>
            {props.status}
          </span>
        </p>
      )}
      {props.status === "approved" && (
        <p
          style={{
            color: "green",
          }}>
          {props.showIcon && <CheckOutlined />}

          <span
            style={{
              marginLeft: "10px",
            }}>
            {props.status}
          </span>
        </p>
      )}
      {props.status === "rejected" && (
        <p
          style={{
            color: "red",
          }}>
          {props.showIcon && <CloseOutlined />}

          <span
            style={{
              marginLeft: "10px",
            }}>
            {props.status}
          </span>
        </p>
      )}
      {props.status === "flag" && (
        <p
          style={{
            color: "red",
          }}>
          {props.showIcon && <FlagOutlined />}
          <span
            style={{
              marginLeft: "10px",
            }}>
            {props.status}
          </span>
        </p>
      )}
    </div>
  );
};

export default ShowStatus;
