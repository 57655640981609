import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Column } from "@ant-design/plots";

const DoubleBar = (props) => {
  const [data, setData] = useState([
    {
      name: "Revenue",
      department: "NEPHROLOGY",
      rupee: 22000,
    },
    {
      name: "Revenue",
      department: "EHC",
      rupee: 1000,
    },
    {
      name: "Revenue",
      department: "PLASTIC SURGERY",
      rupee: 11200,
    },
    {
      name: "Direct",
      department: "NEPHROLOGY",
      rupee: 8700,
    },
    {
      name: "Direct",
      department: "EHC",
      rupee: 130,
    },
    {
      name: "Direct",
      department: "PLASTIC SURGERY",
      rupee: 10,
    },
  ]);
  const config = {
    data,
    isGroup: true,
    xField: "department",
    yField: "rupee",
    seriesField: "name",

    //color: ['#1ca9e6', '#f88c24'],

    // marginRatio: 0.1,
    label: {
      position: "middle",
      // 'top', 'middle', 'bottom'
      layout: [
        {
          type: "interval-adjust-position",
        },
        {
          type: "interval-hide-overlap",
        },
        {
          type: "adjust-color",
        },
      ],
    },
  };
  useEffect(() => {
    if (props.data && props.data.length > 0) {
      setData(props.data);
    }
  }, [props]);
  return <Column {...config} />;
};

export default DoubleBar;
