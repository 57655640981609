import {
  Breadcrumb,
  Button,
  Card,
  Tag,
  theme,
  Tooltip,
  Drawer,
  Timeline,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../components/sidebar";
import { TableComp } from "sz-react-utils4";
import moment from "moment";
import {
  BookOutlined,
  DollarOutlined,
  IdcardOutlined,
  MessageOutlined,
  PlusOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import { NeuroData } from "../constraints/deparatmentData";
import "../styles/globalStyle.css";
const Listing = () => {
  const [count, setCount] = useState();
  const [drawerVis, setDrawerVis] = useState(false);
  const tableRef = useRef();
  const {
    token: { colorBgContainer, colorPrimaryBg, colorText },
  } = theme.useToken();
  const onClose = () => {
    setDrawerVis(false);
  };
  const myColumns = [
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
      width: 125,
      //   searchTextName: "Patient name",
      fixed: "left",
      sorter: true,
    },
    {
      title: "Contact Details",
      key: "contact",
      dataIndex: "contact",

      width: 200,
      render: (text, record) => {
        console.log(text);
        console.log(record, "; record");
        return (
          <div>
            {record.contact.mobile}

            {/* <br />
            {record.contact.email}
            <br />
            {record.contact.state} */}
          </div>
        );
      },
      //   searchTextName: "Mobile Number",
    },
    {
      sorter: true,
      title: "Guardian Name",
      width: 160,
      dataIndex: "guardianName",
      key: "guardianName",
      // searchTextName: "Guardian Name",
    },
    {
      sorter: true,
      title: "Department",
      dataIndex: "department",
      width: 140,
      key: "department",
      render: (val) => {
        return val.map((item, key) => {
          return (
            <Tag key={key} className="mt-2">
              {item}
            </Tag>
          );
        });
      },
    },
    {
      title: "Blood Type",
      dataIndex: "type",
      width: 100,
      key: "type",
      render: (val) => {
        function color() {
          if (val === "A") return "orange";
          else if (val === "B") return "green";
          else return "red";
        }
        return (
          <>
            <Tag color={color()}>{val}</Tag>
          </>
        );
      },
    },
    {
      title: "Latest Report",
      width: 200,
      dataIndex: "latestReport",
      key: "latestReport",
    },
    {
      title: "Status",
      dataIndex: "status",
      width: 150,
      key: "status",
      render: (val) => {
        function color() {
          if (val === "serious") return "red";
          else if (val === "normal") return "green";
          else return "orange";
        }
        return (
          <Tag style={{ textTransform: "uppercase" }} color={color()}>
            {val}
          </Tag>
        );
      },
    },
    {
      title: "Created At",
      key: "createdAt",
      type: "date",
      width: 150,
      sorter: true,
      render: (createdAt) => {
        return <Tag>{moment(new Date()).format("lll")}</Tag>;
      },
    },

    {
      key: "actions",
      title: "Actions",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip color={colorPrimaryBg} title="Add Query">
              <Button
                shape="circle"
                style={{ marginRight: 6, marginBottom: 6 }}
                size="small"
                icon={<MessageOutlined />}
              />
            </Tooltip>
            <Tooltip color={colorPrimaryBg} title="Change Status">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<BookOutlined />}
              />
            </Tooltip>
            <Tooltip color={colorPrimaryBg} title="View Report">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<ProfileOutlined />}
                onClick={() => {
                  setDrawerVis(true);
                }}
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ];
  const [selectedRowKeys, setSeletedRowKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      setSeletedRowKeys(selectedRowKeys);
    },
  };
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      //   params.status = "Open";
      //   if (!params.sortField) {
      //     params.sortField = "createdAt";
      //     params.sortOrder = "descend";
      //   }
      //   let data = await getAllComplaintsAction({
      //     ...params,
      //     regExFilters: [
      //       "complaintRaisedByName",
      //       "complaintDetails",
      //       "productName",
      //     ],
      //   });
      setCount(NeuroData.total);
      resolve(NeuroData);
    });
  };

  return (
    <Content
      style={{
        margin: "0 16px",
      }}
    >
      <Breadcrumb
        style={{
          margin: "16px 0",
        }}
      >
        <Breadcrumb.Item>Listings</Breadcrumb.Item>
      </Breadcrumb>

      <Card bordered={true}>
        <div className="ml-auto flex-row flex items-center justify-end">
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: colorPrimaryBg,
              marginRight: 10,
            }}
          >
            <DollarOutlined
              style={{ color: "#fff", marginRight: 5, fontSize: 18 }}
            />
            <text style={{ color: "#fff", fontWeight: "500" }}>REVENUE</text>
          </Button>
          <Button
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: colorPrimaryBg,
            }}
          >
            <PlusOutlined
              style={{ color: "#fff", fontSize: 18, marginRight: 5 }}
            />
            <text style={{ color: "#fff", fontWeight: "500" }}>ADD</text>
          </Button>
        </div>

        <TableComp
          ref={tableRef}
          columns={myColumns}
          extraProps={{ scroll: { x: 300 } }}
          // apiRequest={apiRequest}
          dataSource={NeuroData}
          pagination={{
            defaultPageSize: 10,
            pageSizeOptions: ["10", "25", "50", "100"],
            showSizeChanger: true,
          }}
        />
      </Card>
      <Drawer
        title="All Reports"
        width={500}
        placement="right"
        onClose={onClose}
        visible={drawerVis}
      >
        <Timeline mode={"left"} style={{ padding: "20px" }}>
          <Timeline.Item>
            <a onClick={() => {}}>
              Blood Pressure by <b>{"Dr. Goel"}</b> on{" "}
              <b>{moment(new Date()).format("lll")}</b>
            </a>
          </Timeline.Item>
          <Timeline.Item>
            <a onClick={() => {}}>
              Diabetes by <b>{"Dr. Goel"}</b> on{" "}
              <b>{moment(new Date()).format("lll")}</b>
            </a>
          </Timeline.Item>
        </Timeline>
      </Drawer>
    </Content>
  );
};

export default Listing;
