import {
  Button,
  Card,
  Row,
  Col,
  theme,
  Tooltip,
  Drawer,
  Modal,
  Select,
  message,
  Dropdown,
  Form,
  Menu,
  InputNumber,
  Breadcrumb,
  Tag,
  Radio,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { TableComp } from "sz-react-utils4";
import moment from "moment";
import {
  ProfileOutlined,
  CaretDownOutlined,
  CheckOutlined,
  EditOutlined,
  CloseOutlined,
  BookOutlined,
  LoadingOutlined,
  FlagOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import "../styles/globalStyle.css";
import {
  CalculatDoctorPayoutAction,
  ChangeDoctorCommissionAction,
  GetAllDoctorsAction,
  GetAllServiceItemsAction,
  GetDoctorServicesAction,
  GetOneServiceItemAction,
  UpdateDoctorCommission,
  UpdateDoctorStatusAction,
  GetDoctorPayoutByDateAction,
} from "../utils/action";
import { ChangeDoctorCommissionUrl } from "../utils/api";
import ShowStatus from "../components/ShowStatus";
const { Option } = Select;
// ["pending", "flag", "approved", "rejected"],
const options = [
  { label: "Pending", value: "pending" },
  { label: "Reject", value: "rejected" },
  { label: "Approved", value: "approved" },
  { label: "Flag", value: "flag" },
];

const payoutDateOptions = [
  { label: "3month", value: "3month" },
  { label: "6month", value: "6month" },
  { label: "lastyear", value: "lastyear" },
  { label: "overall", value: "overall" },
];

const Doctors = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [drawerVis, setDrawerVis] = useState(false);
  const [edit, setEdit] = useState(false);
  const [currDoc, setCurrDoc] = useState();
  const [refresh, setRefresh] = useState(new Date());
  const [inputValues, setInputValues] = useState({});
  const [selectedPayoutDate, setSelectedPayoutDate] = useState("overall");
  const [statusModalVis, setStatusModalVis] = useState(false);
  const [doctorsPayoutData, setDoctorPayoutData] = useState();
  const [form] = Form.useForm();

  const tableRefDoc = useRef();
  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();

  const tableRef = useRef();
  // get the data of all doctors from the server
  const getDoctorData = async () => {
    setLoading(true);
    const { data } = await GetAllDoctorsAction();
    const { doctors } = data;
    console.log(doctors);
    setData(doctors);
    setLoading(false);
  };
  useEffect(() => {
    getDoctorData();
  }, []);

  const [editRow, setEditRow] = useState({}); // State to track the edit state of each row

  const handleEditRow = (record) => {
    if (!editRow[record._id]) {
      // Entering edit mode
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [record._id]: record.commission, // Store the original value when entering edit mode
      }));
    } else {
      // Exiting edit mode
      setInputValues((prevInputValues) => {
        const updatedInputValues = { ...prevInputValues };
        delete updatedInputValues[record._id]; // Remove the stored input value when exiting edit mode
        return updatedInputValues;
      });
    }

    setEditRow((prevEditRow) => ({
      ...prevEditRow,
      [record._id]: !prevEditRow[record._id], // Toggle the edit state for the specific row
    }));
  };
  const handleInputChange = (val, record) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [record._id]: val, // Store the input value for the specific row
    }));
  };
  const {
    token: { colorPrimaryBg },
  } = theme.useToken();
  const onClose = () => {
    setDrawerVis(false);
  };
  const handleCancel = () => {
    form.resetFields();
    setStatusModalVis(false);
  };

  const handleOkStatus = () => {
    setStatusModalVis(false);
    messageApi.open({
      type: "success",
      content: "Status Changed Successfully!",
    });
  };

  const handelStatusSubmit = async (value) => {
    console.log("status", value);

    // send the api call to update the doctor status
    const { data } = await UpdateDoctorStatusAction({
      id: currDoc._id,
      status: value.status,
    });

    console.log(data);
    if (data.success) {
      setStatusModalVis(false);
      tableRefDoc.current.reload();
      messageApi.open({
        type: "success",
        content: "Status Changed Successfully!",
      });
    } else {
      console.log("error updating status");
    }
  };

  const handleCheck = async (text, record) => {
    console.log(text, "real value");
    console.log(inputValues, "value to change");
    // inputValues[record._id]
    // Extract the doctorId,serviceId and Commission from the inputValues
    const serviceId = Object.keys(inputValues)[0];
    const commission = Object.values(inputValues)[0];
    const doctorId = currDoc._id;
    // api to get  selected serviceItem data
    const { data } = await GetOneServiceItemAction(serviceId);

    const { service } = data.data;
    // console.log("service", service);

    // serviceItemCode, serviceName, doctorId, commission
    // calling api to change the doctor commission
    let data22 = await ChangeDoctorCommissionAction({
      serviceItemCode: service.serviceItemCode,
      serviceName: service.serviceName,
      doctorId,
      commission,
    });
    if (data22) {
      tableRef.current.reload();
      tableRefDoc.current.reload();
    } else {
      console.log("Message Error!");
    }
    // reload the table
  };
  const allServices = [
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
      width: 150,
      fixed: "left",
    },
    {
      title: "Service Item Code",
      dataIndex: "serviceItemCode",
      key: "serviceItemCode",
      width: 80,
      searchTextName: "Item Code",
    },
    {
      title: "Commission(%)",
      dataIndex: "commission",
      key: "commission",
      width: 100,
      render: (text, record) => {
        return (
          <div className="flex flex-row justify-between items-center">
            {/* {text} */}
            <InputNumber
              placeholder="Commission"
              defaultValue={text}
              value={text}
              disabled={!editRow[record._id]} // Enable/disable based on the row's edit state
              onChange={(val) => handleInputChange(val, record)}
            />
            {!editRow[record._id] ? (
              <Button
                shape="circle"
                style={{ marginRight: "10px" }}
                size="small"
                icon={<EditOutlined />}
                disabled={edit}
                onClick={() => {
                  setEdit(!edit);
                  handleEditRow(record);
                }}
              />
            ) : (
              <>
                <Button
                  shape="circle"
                  style={{ marginRight: "10px" }}
                  size="small"
                  icon={<CheckOutlined />}
                  onClick={() => {
                    setEdit(!edit);

                    handleEditRow(record);
                    handleCheck(text, record);
                  }}
                />
                <Button
                  shape="circle"
                  style={{ marginRight: "10px" }}
                  size="small"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    setEdit(!edit);

                    handleEditRow(record);
                  }}
                />
              </>
            )}
          </div>
        );
      },
    },
  ];
  const myColumns = [
    {
      title: "Doctor Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      fixed: "left",
      searchTextName: "Name",
    },
    {
      title: "Doctor ID",
      dataIndex: "_id",
      key: "_id",
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      filters: ["pending", "approved", "flag", "reject"].map((x) => {
        return { text: x, value: x };
      }),
      render: (_, record) => {
        return <ShowStatus status={record.status} showIcon={true} />;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      render: (val) => {
        return <Tag>{val}</Tag>;
      },
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: 100,
    },
    {
      title: "Doctor Payout(₹)",
      key: "payout",
      dataIndex: "payout",
      width: 120,
      render: (_, record) => {
        return <p>{record.payout.toFixed(2)}</p>;
      },
    },
    {
      title: "Services",
      dataIndex: "services",
      key: "services",
      width: 110,
      render: (services) => (
        <Dropdown
          overlay={
            <Menu>
              {services.map((service) => (
                <Menu.Item key={service._id}>
                  <div>
                    <p>ServiceName: {service.serviceName} </p>
                    <p>ServiceItemCode: {service.serviceItemCode}</p>
                    <p>Commission: {service.commission}</p>
                  </div>
                  <hr></hr>
                </Menu.Item>
              ))}
            </Menu>
          }
          trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            View Services <CaretDownOutlined />
          </a>
        </Dropdown>
      ),
    },
    {
      title: "Group Name",
      dataIndex: "groupDetails",
      key: "groupDetails",
      width: 150,
      render: (groupDetails) => {
        return (
          <div>
            <div>{groupDetails.group ? groupDetails.group.name : "NA"}</div>
          </div>
        );
      },
    },
    {
      title: "Group Status",
      dataIndex: "groupDetails",
      key: "groupDetails",
      width: 150,
      render: (groupDetails) => {
        return (
          <div>
            <div>{groupDetails.groupStatus}</div>
          </div>
        );
      },
    },

    {
      key: "actions",
      title: "Actions",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip color={colorPrimaryBg} title="Update Doctor Commission">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<ProfileOutlined />}
                onClick={() => {
                  console.log(record, " this is doctor record");
                  setCurrDoc(record);
                  setDrawerVis(true);
                  setRefresh(new Date());
                }}
              />
            </Tooltip>
            <Tooltip color={colorPrimaryBg} title="Change Status">
              <Button
                onClick={() => {
                  setStatusModalVis(true);
                  form.setFieldsValue({
                    status: record.status,
                  });
                  setCurrDoc(record);
                }}
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<BookOutlined />}
              />
            </Tooltip>
            <Tooltip color={colorPrimaryBg} title="Edit Doctor ">
              <Button
                onClick={() => {
                  setCurrDoc(record);
                  navigate(`/doctors/edit/${record._id}`);
                }}
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<BookOutlined />}
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ];
  const myColumns2 = [
    {
      title: "Doctor Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      fixed: "left",
      searchTextName: "Name",
    },
    {
      title: "Doctor ID",
      dataIndex: "_id",
      key: "_id",
      width: 150,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      filters: ["pending", "approved", "flag", "reject"].map((x) => {
        return { text: x, value: x };
      }),
      render: (_, record) => {
        return <ShowStatus status={record.status} showIcon={true} />;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200,
      render: (val) => {
        return <Tag>{val}</Tag>;
      },
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      width: 100,
    },
    {
      title: "Doctor Payout(₹)",
      key: "payout",
      dataIndex: "rqPayout",
      width: 120,
      render: (_, record) => {
        return <p>{record.rqPayout.toFixed(2)}</p>;
      },
    },
    {
      title: "Services",
      dataIndex: "services",
      key: "services",
      width: 110,
      render: (services) => (
        <Dropdown
          overlay={
            <Menu>
              {services.map((service) => (
                <Menu.Item key={service._id}>
                  <div>
                    <p>ServiceName: {service.serviceName} </p>
                    <p>ServiceItemCode: {service.serviceItemCode}</p>
                    <p>Commission: {service.commission}</p>
                  </div>
                  <hr></hr>
                </Menu.Item>
              ))}
            </Menu>
          }
          trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            View Services <CaretDownOutlined />
          </a>
        </Dropdown>
      ),
    },
    {
      title: "Group Name",
      dataIndex: "groupDetails",
      key: "groupDetails",
      width: 150,
      render: (groupDetails) => {
        return (
          <div>
            <div>{groupDetails.group ? groupDetails.group.name : "NA"}</div>
          </div>
        );
      },
    },
    {
      title: "Group Status",
      dataIndex: "groupDetails",
      key: "groupDetails",
      width: 150,
      render: (groupDetails) => {
        return (
          <div>
            <div>{groupDetails.groupStatus}</div>
          </div>
        );
      },
    },

    {
      key: "actions",
      title: "Actions",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip color={colorPrimaryBg} title="Update Doctor Commission">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<ProfileOutlined />}
                onClick={() => {
                  console.log(record, " this is doctor record");
                  setCurrDoc(record);
                  setDrawerVis(true);
                  setRefresh(new Date());
                }}
              />
            </Tooltip>
            <Tooltip color={colorPrimaryBg} title="Change Status">
              <Button
                onClick={() => {
                  setStatusModalVis(true);
                  form.setFieldsValue({
                    status: record.status,
                  });
                  setCurrDoc(record);
                }}
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<BookOutlined />}
              />
            </Tooltip>
            <Tooltip color={colorPrimaryBg} title="Edit Doctor ">
              <Button
                onClick={() => {
                  setCurrDoc(record);
                  navigate(`/doctors/edit/${record._id}`);
                }}
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<BookOutlined />}
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    tableRef.current?.reload();
  }, [refresh]);

  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      // if (!params.sortField) {
      //   params.sortField = "createdDateTime";
      //   params.sortOrder = "descend";
      // }
      let { data } = await GetDoctorServicesAction(currDoc._id, {
        ...params,
        regExFilters: ["serviceItemCode"],
      });
      resolve(data);
    });
  };
  const apiRequest2 = (params) => {
    return new Promise(async (resolve) => {
      // if (!params.sortField) {
      //   params.sortField = "createdDateTime";
      //   params.sortOrder = "descend";
      // }
      let { data } = await GetAllDoctorsAction({
        ...params,
        regExFilters: ["name"],
      });

      console.log("doctor data", data);
      resolve(data);
    });
  };

  const handleChangePayoutDate = (e) => {
    console.log(e.target.value);
    setSelectedPayoutDate(e.target.value);
  };

  // api request for the payoutDate
  const payoutDateRequest = (params) => {
    return new Promise(async (resolve) => {
      let { data } = await GetDoctorPayoutByDateAction(selectedPayoutDate);

      const filterData = data.filter((doctor) => doctor.rqPayout !== 0);

      setDoctorPayoutData(filterData);
      resolve(data);
    });
  };

  useEffect(() => {
    payoutDateRequest();
  }, [selectedPayoutDate]);

  // Search the service by sercie name or serviceItemCode
  const onSearch = async () => {};
  return (
    <Fragment>
      <Breadcrumb style={{ margin: "20px 0" }}>
        <Breadcrumb.Item key={"/doctors"}>{"Doctors"}</Breadcrumb.Item>
      </Breadcrumb>
      <Content
        style={{
          margin: "0 16px",
        }}>
        {contextHolder}
        <Card bordered={true}>
          <div className={"flex flex-row justify-between items-center"}>
            <div className="ml-auto flex flex-row space-x-10 items-center justify-end">
              <Radio.Group
                options={payoutDateOptions}
                onChange={handleChangePayoutDate}
                value={selectedPayoutDate}
                optionType="button"
              />
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: colorPrimaryBg,
                }}
                onClick={() => {
                  navigate("/doctors/new");
                }}>
                <PlusOutlined
                  style={{ color: "#fff", fontSize: 18, marginRight: 5 }}
                />
                <text style={{ color: "#fff", fontWeight: "500" }}>
                  ADD Doctor
                </text>
              </Button>
            </div>
          </div>
          {selectedPayoutDate === "overall" && (
            <TableComp
              ref={tableRefDoc}
              columns={myColumns}
              extraProps={{ scroll: { x: 300 } }}
              // dataSource={data}
              // loading={loading}
              apiRequest={apiRequest2}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: ["10", "25", "50", "100"],
                showSizeChanger: true,
              }}
            />
          )}
          {selectedPayoutDate !== "overall" && (
            <TableComp
              ref={tableRefDoc}
              columns={myColumns2}
              extraProps={{ scroll: { x: 300 } }}
              dataSource={doctorsPayoutData}
              // loading={loading}
              // apiRequest={payoutDateRequest}
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: ["10", "25", "50", "100"],
                showSizeChanger: true,
              }}
            />
          )}
        </Card>
        <Drawer
          title="Update Doctor Service Commission"
          width={800}
          placement="right"
          onClose={onClose}
          open={drawerVis}>
          {/* <div className="w-full flex justify-end">
          {edit ? (
            <div>
              <Button
                shape="circle"
                style={{ marginRight: "10px" }}
                size="small"
                icon={<CheckOutlined />}
                onClick={() => {
                  setEdit(false);
                }}
              />
              <Button
                shape="circle"
                size="small"
                icon={<CloseOutlined />}
                onClick={() => {
                  setEdit(false);
                }}
              />
            </div>
          ) : (
            <Button
              shape="circle"
              size="small"
              icon={<EditOutlined />}
              onClick={() => {
                setEdit(true);
              }}
            />
          )}
        </div> */}
          {drawerVis && (
            <Card>
              <div>
                <h2>Basic Doctor information</h2>
                <div>
                  <p>{`Doctor ID:-${currDoc._id}`}</p>
                  <p>{`Doctor Email:-${currDoc.email}`}</p>
                  <p>{`Doctor Department:-${currDoc.department}`}</p>
                </div>
              </div>
            </Card>
          )}

          <TableComp
            ref={tableRef}
            columns={allServices}
            extraProps={{ scroll: { x: 300 } }}
            // loading={loading}
            apiRequest={apiRequest}
            pagination={{
              defaultPageSize: 10,
              pageSizeOptions: ["10", "25", "50", "100"],
              showSizeChanger: true,
            }}
          />
        </Drawer>
        <Modal
          title="Change Status"
          open={statusModalVis}
          footer={null}
          onCancel={handleCancel}
          width={380}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Form form={form} onFinish={handelStatusSubmit}>
              <Form.Item label="Status" name="status">
                <Select
                  placeholder="Select Status"
                  style={{
                    width: 200,
                  }}
                  optionFilterProp="label"
                  filterOption={false}
                  options={options}>
                  {options.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item>
                <Row justify="center" align="middle">
                  <Col>
                    <Button type="primary" htmlType="submit">
                      Change Status
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </Content>
    </Fragment>
  );
};

export default Doctors;
