import {
  UserOutlined,
  PlusOutlined,
  UsergroupAddOutlined,
  DollarCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import classes from "../styles/group.module.css";
import { useState, useEffect, useRef } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Avatar,
  Badge,
  Modal,
  Form,
  Input,
  theme,
} from "antd";

import { Fragment } from "react";
import {
  CreateGroupAction,
  GetAllGroupsAction,
  GetAllDoctorsAction,
} from "../utils/action";
import { Select, Space } from "antd";
import { CreateGroupUrl } from "../utils/api";

const { Option } = Select;

const Groups = () => {
  const [groups, setGroups] = useState();
  const [modalVisible, setModalVisible] = useState(false);

  const [filterOptions, setFilterOptions] = useState();
  const [options, setOptions] = useState([]);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    const getDoctorData = async () => {
      const { data } = await GetAllDoctorsAction();
      console.log(data);
      const doctors = data.data;

      const doctorOptions = doctors
        .filter((doctor) => !doctor.groupDetails.group)
        .map((doctor) => ({
          value: doctor._id,
          label: `${doctor.name}(${doctor._id})`,
        }));

      setOptions(doctorOptions);
    };
    getDoctorData();
  }, []);

  useEffect(() => {
    const getGroupsData = async () => {
      const data = await GetAllGroupsAction();
      setGroups(data.data.doctorGroups);
    };
    getGroupsData();
  }, []);

  const handleSubmit = async (value) => {
    console.log(value);
    try {
      const data = await axios.post(CreateGroupUrl(), value);
      // console.log(data);
      // const data = await CreateGroupAction(value);

      //  Fetch the updated group data
      const groupsData = await GetAllGroupsAction();
      setGroups(groupsData.data.doctorGroups);

      // on success close modal
      setModalVisible(false);
      setShowError(false);
      setErrorMsg(null);
    } catch (err) {
      console.log("Error occurred:", err.message);
      setModalVisible(true);
      setShowError(true);
      setErrorMsg(err.response.data.message);
    }
  };
  const {
    token: { colorBgContainer, colorPrimaryBg },
  } = theme.useToken();

  // for doctor option in the select feild
  useEffect(() => {
    const getDoctorData = async () => {
      const { data } = await GetAllDoctorsAction();
      console.log(data);
      const doctors = data.data;
      const filterDoctors = doctors.filter(
        (doctor) => doctor.groupDetails.group === null
      );

      // console.log("filter doctor", filterDoctors);
      const doctorOptions = filterDoctors.map((doctor) => ({
        value: doctor._id,
        label: `${doctor.name}(${doctor._id})`,
      }));

      setOptions(doctorOptions);
    };
    getDoctorData();
  }, []);

  // handel the search in the doctorNameChange model
  const handleSearch = (value) => {
    const filteredOptions = options.filter((option) => {
      const label = option.label.toLowerCase();
      value = value.toLowerCase();
      return (
        label.includes(value) || // Search by name
        option.value.includes(value) // Search by ID
      );
    });
    setFilterOptions(filteredOptions);
  };

  // restore the option array when there is no search result
  useEffect(() => {
    if (filterOptions?.length === 0 && options.length > 0) {
      setFilterOptions(options);
    }
  }, [options]);

  return (
    <Fragment>
      <div className={classes.box}>
        {groups &&
          groups.map((group) => {
            return (
              <Card
                style={{
                  width: 300,
                }}
                className={classes.card}
                onClick={() => navigate(`/groups/${group._id}`)}
              >
                <div className={classes.header}>
                  <div
                    className={"flex flex-col justify-center items-center mb-2"}
                  >
                    <Avatar
                      className={classes.circle}
                      style={{ backgroundColor: "orange" }}
                      icon={<TeamOutlined />}
                    />
                    <h4 className="ml-0">{group.name}</h4>
                  </div>
                  <div className="mb-4">
                    <p>Payout Assigned: ₹{group.totalGroupPayout}</p>
                  </div>
                  <div className={classes.total}>
                    <h5>Total Doctors:</h5>
                    <Badge count={group.doctors.length}>
                      <TeamOutlined style={{ fontSize: "20px" }} />
                    </Badge>
                  </div>
                </div>
                <Row justify="center" align="middle">
                  <Col>
                    <Button
                      onClick={() => navigate(`/groups/${group._id}`)}
                      type="primary"
                      className={classes.btn}
                    >
                      Show More Details
                    </Button>
                  </Col>
                </Row>
              </Card>
            );
          })}
      </div>
      <Button
        className={classes.create}
        style={{
          backgroundColor: colorPrimaryBg,
          borderRadius: "50%",
          paddingLeft: "15px",
          paddingRight: "15px",
        }}
        onClick={() => setModalVisible(true)}
      >
        <PlusOutlined style={{ color: "#fff" }} />
      </Button>
      <Modal
        title="Create New Group"
        open={modalVisible}
        width={800}
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <Form onFinish={handleSubmit}>
          <Form.Item
            name="name"
            label="Group Name"
            rules={[
              {
                required: true,
                message: "Please enter Group Name",
              },
            ]}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
          >
            <Input
              placeholder="Enter Group Name"
              // value={inputValue}
              // onChange={(e) => setInputValue(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            name="doctors"
            label="Doctors"
            rules={[
              {
                required: true,
                message: "Please enter Doctors ID",
              },
            ]}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
          >
            <Select
              mode="multiple"
              placeholder="Please select Doctors"
              style={{
                width: "100%",
              }}
              showSearch
              optionFilterProp="label"
              filterOption={false}
              onSearch={handleSearch}
              options={filterOptions}
            >
              {options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {showError && <p className={classes.error}>{errorMsg}</p>}
          <Row justify="center" align="middle">
            <Col>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Fragment>
  );
};

export default Groups;
