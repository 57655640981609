// const API_URL = "http://localhost:5000/api/v1";
const API_URL = "https://analytics-api-50ju.onrender.com/api/v1";
// const API_URL = "http://18.183.3.228:5000/api/v1";

///////live on render
// const API_URL = "https://analytics-apiv2.onrender.com/api/v1";

export const GetAllInvoices = () => {
  return API_URL + "/invoices";
};
export const GetAllIndirectConsUrl = () => {
  return API_URL + "/indirects";
};
export const InvoiceUploadUrl = () => {
  return API_URL + "/invoiceUpload";
};
export const PushInvoiceUrl = () => {
  return API_URL + "/invoice/new";
};
export const GetIndirectDataUrl = () => {
  return API_URL + "/calAlloc";
};
export const GetFinalReportUrl = () => {
  return API_URL + "/finalReport";
};
export const DeductionUrl = () => {
  return API_URL + "/deduction";
};
export const ChangeAllocationUrl = () => {
  return API_URL + "/allocation/new";
};
export const GetAllocationChartUrl = () => {
  return API_URL + "/allocation";
};
export const GetAllDoctorsUrl = () => {
  return API_URL + "/doctors";
};
export const UpdateDoctorCommissionUrl = () => {
  return API_URL + "/doctors/updateDoctorCommission";
};
export const GetAllServiceItemsUrl = () => {
  return API_URL + "/serviceItem";
};
export const AddServiceItemsUrl = () => {
  return API_URL + "/serviceItem/new";
};
export const EditDefaultCommUrl = () => {
  return API_URL + "/serviceItem/editComm";
};
export const GetAllGroupsUrl = () => {
  return API_URL + "/doctorGroups";
};
export const GetOneGroupUrl = (groupId) => {
  return API_URL + `/doctorGroups/${groupId}`;
};
export const DeleteGroupUrl = () => {
  return API_URL + "/doctorGroups/remove";
};
export const DeleteDoctorFromGroupUrl = (doctorId) => {
  return API_URL + `/doctorGroups/${doctorId}`;
};
export const AddDoctorInGroupUrl = (groupId, doctorId) => {
  return API_URL + `/doctorGroups/${groupId}/doctors/${doctorId}`;
};
export const GetDoctorServicesUrl = (id) => {
  return API_URL + "/doctorServices/" + id;
};
export const CalculateDoctorPayoutUrl = () => {
  return API_URL + "/doctors/payout";
};

export const CreateGroupUrl = () => {
  return API_URL + "/doctorGroups/new";
};

export const ChangeInvoiceDoctorNameUrl = () => {
  return API_URL + "/invoices/changeDoctorName";
};
export const ChangeDoctorCommissionUrl = () => {
  return API_URL + "/doctors/updateDoctorCommission";
};
export const GetOneServiceItemUrl = (serviceId) => {
  return API_URL + `/serviceItem/${serviceId}`;
};
export const UpdateServiceDefaultCommissionUrl = (serviceId) => {
  return API_URL + "/serviceItem/editComm";
};
export const UpdateDoctorStatusUrl = () => {
  return API_URL + "/doctors/update";
};
export const AddDoctorUrl = () => {
  return API_URL + "/doctors/new";
};
export const UpdateGroupTotalPayoutUrl = () => {
  return API_URL + "/doctorGroups/updatePayout";
};
export const UpdateDoctorGroupCommissionUrl = () => {
  return API_URL + "/doctors/updateDoctorGroupComm";
};
export const EditGroupNameUrl = () => {
  return API_URL + "/doctorGroups/editGroupName";
};
export const EditDoctorUrl = (doctorId) => {
  return API_URL + `/doctors/edit/${doctorId}`;
};
export const GetOneDoctorUrl = (doctorId) => {
  return API_URL + `/doctors/${doctorId}`;
};
export const EditServiceItemUrl = (serviceId) => {
  return API_URL + `/serviceItem/edit/${serviceId}`;
};
export const ChangeDoctorGroupStatusUrl = () => {
  return API_URL + "/doctors/changeGroupStatus";
};

export const GetDoctorPayoutByDateUrl = (period) => {
  return API_URL + `/doctors/payoutbydate/${period}`;
};
