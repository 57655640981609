import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Column } from "@ant-design/plots";

const BasicBar = (props) => {
  const [data, setData] = useState([
    {
      department: "NEUROLOGY",
      value: 38,
    },
    {
      department: "PLASTIC SURGERY",
      value: 52,
    },
    {
      department: "EHC",
      value: 61,
    },
  ]);
  const config = {
    data,
    xField: "department",
    yField: "value",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    color: ({ department }) => {
      if (department === "NEPHROLOGY") {
        return "#62DAAB";
      } else if (department === "EHC") return "#657798";
      else return "#6495F7";
    },
    minColumnWidth: 50,
    maxColumnWidth: 50,
  };
  useEffect(() => {
    if (props.data && props.data.length > 0) {
      setData(props.data);
    }
  }, [props]);
  return <Column {...config} />;
};

export default BasicBar;
