import { Button, Card, theme, InputNumber, Breadcrumb, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { TableComp } from "sz-react-utils4";
import {
  PlusOutlined,
  CheckOutlined,
  EditOutlined,
  CloseOutlined,
  FileFilled,
} from "@ant-design/icons";
import "../styles/globalStyle.css";
import {
  GetAllServiceItemsAction,
  UpdateServiceDefaultCommissionAction,
} from "../utils/action";

const ServiceItems = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [inputValues, setInputValues] = useState({});
  const [edit, setEdit] = useState(false);
  const [editRow, setEditRow] = useState({}); // State to track the edit state of each row

  const tableRef = useRef();
  const navigate = useNavigate();

  const {
    token: { colorPrimaryBg },
  } = theme.useToken();

  // get the data of all service items from the server
  const getServicesData = async () => {
    setLoading(true);
    const { data } = await GetAllServiceItemsAction({
      // results: 100,
      // count: 100,
    });
    console.log(data);
    setData(data.data);
    setLoading(false);
  };
  const apiRequest = (params) => {
    return new Promise(async (resolve) => {
      // if (!params.sortField) {
      //   params.sortField = "createdDateTime";
      //   params.sortOrder = "descend";
      // }
      let { data } = await GetAllServiceItemsAction({
        ...params,
        regExFilters: ["serviceItemCode"],
      });
      resolve(data);
    });
  };
  const handleEditRow = (record) => {
    if (!editRow[record._id]) {
      // Entering edit mode
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [record._id]: record.commission, // Store the original value when entering edit mode
      }));
    } else {
      // Exiting edit mode
      setInputValues((prevInputValues) => {
        const updatedInputValues = { ...prevInputValues };
        delete updatedInputValues[record._id]; // Remove the stored input value when exiting edit mode
        return updatedInputValues;
      });
    }

    setEditRow((prevEditRow) => ({
      ...prevEditRow,
      [record._id]: !prevEditRow[record._id], // Toggle the edit state for the specific row
    }));
  };
  const handleInputChange = (val, record) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [record._id]: val, // Store the input value for the specific row
    }));
  };

  const handleCheck = async (text, record) => {
    console.log(text, "real value");
    console.log(inputValues, "value to change");

    // Extract the serviceId and Commission from the inputValues
    const serviceId = Object.keys(inputValues)[0];
    const commission = Object.values(inputValues)[0];

    console.log(serviceId, commission);

    // api call to update the service commmission
    const { data } = await UpdateServiceDefaultCommissionAction({
      id: serviceId,
      defaultComm: commission,
    });

    console.log(data);
    if (data.success) {
      tableRef.current.reload();
      getServicesData();
    } else {
      console.log("error in Updating default service commission");
    }
  };

  const myColumns = [
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
      width: 150,
      fixed: "left",
    },
    {
      title: "Service Item Code",
      dataIndex: "serviceItemCode",
      key: "serviceItemCode",
      width: 80,
    },
    {
      title: "service Type",
      dataIndex: "serviceType",
      key: "serviceType",
      width: 80,
    },
    {
      title: "Service Group Name",
      dataIndex: "serviceGroupName",
      key: "serviceGroupName",
      width: 80,
    },

    {
      title: "Default Commission",
      dataIndex: "defaultComm",
      key: "defaultComm",
      width: 100,
      render: (text, record) => {
        return (
          <div className="flex flex-row justify-between items-center">
            {/* {text} */}
            <InputNumber
              placeholder="Commission"
              defaultValue={text}
              value={text}
              disabled={!editRow[record._id]} // Enable/disable based on the row's edit state
              onChange={(val) => handleInputChange(val, record)}
            />
            {!editRow[record._id] ? (
              <Button
                shape="circle"
                style={{ marginRight: "10px" }}
                size="small"
                icon={<EditOutlined />}
                disabled={edit}
                onClick={() => {
                  setEdit(!edit);
                  handleEditRow(record);
                }}
              />
            ) : (
              <>
                <Button
                  shape="circle"
                  style={{ marginRight: "10px" }}
                  size="small"
                  icon={<CheckOutlined />}
                  onClick={() => {
                    setEdit(!edit);

                    handleEditRow(record);
                    handleCheck(text, record);
                  }}
                />
                <Button
                  shape="circle"
                  style={{ marginRight: "10px" }}
                  size="small"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    setEdit(!edit);

                    handleEditRow(record);
                  }}
                />
              </>
            )}
          </div>
        );
      },
    },
    {
      key: "actions",
      title: "Actions",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return (
          <React.Fragment>
            <Tooltip color={colorPrimaryBg} title="Edit ServiceItem">
              <Button
                shape="circle"
                style={{ marginRight: 6 }}
                size="small"
                icon={<FileFilled />}
                onClick={() => {
                  navigate(`/serviceItems/edit/${record._id}`);
                }}
              />
            </Tooltip>
          </React.Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    getServicesData();
  }, []);

  return (
    <Fragment>
      <Breadcrumb style={{ margin: "20px 0" }}>
        <Breadcrumb.Item key={"/serviceItems"}>
          {"ServiceItems"}
        </Breadcrumb.Item>
      </Breadcrumb>
      <Content
        style={{
          margin: "0 16px",
        }}>
        <Card bordered={true}>
          <div className={"flex flex-row justify-between items-center"}>
            <div className="ml-auto flex-row flex items-center justify-end">
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: colorPrimaryBg,
                }}
                onClick={() => {
                  navigate("/serviceItems/new");
                }}>
                <PlusOutlined
                  style={{ color: "#fff", fontSize: 18, marginRight: 5 }}
                />
                <text style={{ color: "#fff", fontWeight: "500" }}>ADD</text>
              </Button>
            </div>
          </div>
          <TableComp
            ref={tableRef}
            columns={myColumns}
            extraProps={{ scroll: { x: 300 } }}
            apiRequest={apiRequest}
            pagination={{
              defaultPageSize: 10,
              pageSizeOptions: ["10", "25", "50", "100"],
              showSizeChanger: true,
            }}
          />
        </Card>
      </Content>
    </Fragment>
  );
};

export default ServiceItems;
